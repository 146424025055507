import * as React from 'react';
import { EventLink } from '../../Calendar/styled';
import { Box, Flex } from 'components/Layout';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import birthdayAsset from 'routes/Dashboard/assets/birthday.svg';
import anniversaryAsset from 'routes/Dashboard/assets/anniversary.svg';
import firstDayAsset from 'routes/Dashboard/assets/first-day.svg';

interface Props {
    type: 'birthday' | 'firstday' | 'anniversary';
    user: any;
}

const SpecialEvent: React.FC<Props> = props => {
    const { user, type } = props;

    const getSpecialText = () => {
        switch (type) {
            case 'birthday':
                return `Happy birthday ${user.title}!`;
            case 'firstday':
                return `It's ${user.title}'s first day!`;
            case 'anniversary':
                return `It's ${user.title}'s work anniversary!`;
            default:
                return '';
        }
    };
    const getEventImage = () => {
        switch (type) {
            case 'birthday':
                return birthdayAsset;
            case 'firstday':
                return firstDayAsset;
            case 'anniversary':
                return anniversaryAsset;
            default:
                return '';
        }
    };
    const openPingboardUser = () => {
        window.open(`https://dialexa.pingboard.com/users/${user.id}`, '_self');
    };

    return (
        <EventLink
            special
            style={{ width: '100%' }}
            image={getEventImage()}
            status="accepted"
        >
            <Flex align="flex-start">
                <Box margin={[0, 3, 0, 0]}>
                    <Avatar
                        size={43}
                        image={user.image}
                        onClick={() => openPingboardUser()}
                        readOnly
                    />
                </Box>
                <Box fill padding={[2, 0, 0, 0]}>
                    <Typography style={{ maxWidth: '145px' }} variant="label">
                        {getSpecialText()}
                    </Typography>
                </Box>
            </Flex>
        </EventLink>
    );
};

export default SpecialEvent;
