import React, { SFC } from 'react';

import { color } from 'constants/style';
import { GoogleButton, ThemedFlex, Pulse } from './styled';
import Icon from 'components/Icon';
import { Container, Box, Flex } from 'components/Layout';
import { useAuth } from 'providers/Auth';

const Login: SFC = () => {
    const { login } = useAuth();

    return (
        <ThemedFlex fill align="center">
            <Container width={400}>
                <Box fill padding={[0, 0, 40, 0]}>
                    <Flex
                        justify="center"
                        margin={[0, 0, 100, 0]}
                        style={{ position: 'relative' }}
                    >
                        <Icon
                            name="dialexa_robot"
                            color={color.GOLD}
                            size={120}
                            width={200}
                        />
                        <Pulse />
                    </Flex>
                    <GoogleButton type="button" onClick={login}>
                        Login with Google
                    </GoogleButton>
                </Box>
            </Container>
        </ThemedFlex>
    );
};

export default Login;
