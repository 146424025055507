import React from 'react';
import { StyledLoading } from './styled';

const Loading: React.FC<{}> = () => {
    return (
        <StyledLoading>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </StyledLoading>
    );
};

export default Loading;
