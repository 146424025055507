import styled from 'styled-components';
import { themedBackground } from 'constants/theme';

export const BackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(0, 0, 0, 0.75);
    z-index: 500;
`;

export const ContentBox = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    z-index: 1000;
    background: ${themedBackground};
    border-radius: 5px;
    box-shadow: 0 2px 8px rgb(0, 0, 0, 0.26);
`;

export const ActionBar = styled.div`
    margin-top: 25px;
    text-align: right;
`;

export const CloseButton = styled.div`
    position: absolute;
    right: 17px;
    top: 20px;
`;
