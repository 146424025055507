import React, { useMemo, useState } from 'react';
// import EmojiConverter from 'emoji-js';
import { useStatuses } from 'providers/Statuses';
import { Box } from 'components/Layout';
import Typography from 'components/Typography';
import { IconButton } from 'components/Icon';
import Avatar from 'components/Avatar';
import { getDateRange } from 'helpers/formatters';
import { useAuth } from 'providers/Auth';
import ConfirmationModal from './ConfirmationModal';
import { StatusFlex } from '../styled';

// const emoji = new EmojiConverter();
interface Props {
    statusesForToday: any[];
    filteredStatusTypes: string[];
}

const StatusesList: React.FC<Props> = props => {
    const { user } = useAuth();
    const { statusesForToday, filteredStatusTypes } = props;
    const { usersById, statusTypesById, removeStatus } = useStatuses();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
        false
    );
    const [selectedStatusId, setSelectedStatusId] = useState<string>('');

    const filteredStatuses = useMemo(
        () =>
            filteredStatusTypes.length
                ? statusesForToday.filter(status =>
                      filteredStatusTypes.includes(
                          status.status_type_id.toString()
                      )
                  )
                : statusesForToday,
        [statusesForToday, filteredStatusTypes]
    );

    const handleRemoveStatus = (statusId: string) => {
        setShowConfirmationModal(true);
        setSelectedStatusId(statusId);
    };

    const handleRemoveConfirm = () => {
        removeStatus(selectedStatusId);
        setSelectedStatusId('');
        setShowConfirmationModal(false);
    };

    return (
        <>
            {filteredStatuses.map(status => {
                const pingboardUser = usersById[status.user_id];
                const statusType = statusTypesById?.[status.status_type_id];
                return (
                    pingboardUser && (
                        <StatusFlex key={status.id}>
                            <Box margin={[0, 3, 0, 0]}>
                                <Avatar
                                    image={
                                        pingboardUser.avatar_urls &&
                                        pingboardUser.avatar_urls.small
                                    }
                                    size={43}
                                    onClick={() =>
                                        window.open(
                                            `https://dialexa.pingboard.com/users/${pingboardUser.id}`,
                                            '_blank'
                                        )
                                    }
                                />
                            </Box>
                            <Box fill padding={[2, 0, 0, 0]}>
                                <Typography variant="label">{`${pingboardUser.first_name} ${pingboardUser.last_name}`}</Typography>
                                <Box margin={0}>
                                    <Typography variant="description">
                                        {`${statusType?.name}${getDateRange(
                                            status.starts_at,
                                            status.ends_at,
                                            status.all_day
                                        )}`}
                                    </Typography>
                                </Box>
                                <Typography variant="small">
                                    {/* emoji.replace_colons(status.message) */}
                                    {status.message}
                                </Typography>
                            </Box>
                            {user && user.email === pingboardUser.email ? (
                                <IconButton
                                    onClick={() =>
                                        handleRemoveStatus(status.id)
                                    }
                                    name="delete"
                                    size={22}
                                />
                            ) : (
                                ''
                            )}
                            <ConfirmationModal
                                show={showConfirmationModal}
                                hide={() => setShowConfirmationModal(false)}
                                handleConfirm={() => handleRemoveConfirm()}
                            />
                        </StatusFlex>
                    )
                );
            })}
        </>
    );
};

export default StatusesList;
