import { css, createGlobalStyle } from 'styled-components';

import { font, color, textSize } from 'constants/style';
import {
  themedColor,
  themedBackground,
  themedBorder
} from 'constants/theme';
import '../fonts.css';

/**
 * WARNING: This file is not for your quick fix global styling! Please
 *          use styled-components within the components you are writing.
 */

/**
 * Set better box-sizing default along with base html and body styles.
 */
const htmlBodyResets = css`
  * {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  html {
    font-family: sans-serif;
    text-size-adjust: 100%;
    height: 100%;
  }

  body {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    background: ${themedBackground};
    font-family: ${font.BASE};
    font-size: ${textSize.BASE};
    font-weight: 500;
    letter-spacing: 0.005em;
    line-height: 1.5;
    color: ${themedColor};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  div[id="root"] {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
`;

/**
 * Base typeography elements with vertical spacing resets.
 * TODO: Add base styling for HTML headings.
 */
const baseTypeography = css`
  p,
  ul,
  ol,
  dl,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  blockquote,
  pre,
  form,
  table {
    margin: 20px 0 0 0;

    &:first-child {
      margin-top: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  a {
    color: ${color.GOLD};
    text-decoration: none;

    &:hover {
      color: ${color.GOLD_DARK};
    }
  }

  code {
    font-family: ${font.CODE};
  }

  small {
    font-size: ${textSize.SMALL};
  }

  hr {
    border: none;
    height: 1px;
    background: ${themedBorder};
  }
`;

export default createGlobalStyle`
 ${htmlBodyResets} ${baseTypeography}
`;
