import styled, { css } from 'styled-components';

import { transition, color } from 'constants/style';
import {
    themedEventSpecialBackground,
    themedBoxShadowHover,
} from 'constants/theme';
import { InviteResponse } from 'providers/Events/types';

export const EventWrapper = styled.div`
    display: block;
    margin: 0 0 10px;
    border-radius: 6px;
    background: ${themedEventSpecialBackground};
    color: ${color.GOLD};
    transition: 0.2s ${transition.CURVE} all;

    &:hover {
        color: ${color.GOLD_DARK};
        box-shadow: ${themedBoxShadowHover};
    }

    ${({ status }: { status?: InviteResponse }) =>
        status !== 'accepted' &&
        css`
            background: transparent;
            color: ${color.GOLD};
            &:hover {
                color: ${color.GOLD};
            }
        `}
    ${({ status }: { status?: InviteResponse }) =>
        status === 'needsAction' &&
        css`
            border: 1px dashed ${color.GOLD};
        `}
    ${({ status }: { status?: InviteResponse }) =>
        status === 'tentative' &&
        css`
            border: 1px solid ${color.GOLD};
        `}

    animation: fade-in 1.2s ${transition.CURVE} both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const EventLink = styled.a`
    display: block;
    border-radius: 6px;
    padding: 13px 18px 10px 18px;
    background: ${props =>
        props.status === 'accepted'
            ? themedEventSpecialBackground
            : 'transparent'};

    ${(props: { image?: string; special?: boolean; status?: InviteResponse }) =>
        props.image &&
        props.status === 'accepted' &&
        css`
            padding-top: 74px;
            padding-right: 160px;
            background-image: url(${props.image});
            background-size: 190px;
            background-position: 105%;
            background-repeat: no-repeat;
            border: none;
            border-bottom: 1px solid ${themedEventSpecialBackground};
        `}
    ${(props: { image?: string; special?: boolean; status?: InviteResponse }) =>
        props.special &&
        props.status === 'accepted' &&
        css`
            padding-top: 14px;
            height: 100px;
            margin: 0 0 10px;
        `};
`;

export const EventActionBar = styled.div`
    margin: 3px;
    display: flex;
    justify-content: space-between;
`;
