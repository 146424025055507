import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import Input from '../Input';
import { useTheme } from 'providers/Theme';

import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

interface Props extends ReactDatePickerProps {
    error?: string;
    id: string;
    name: string;
    label: string;
    touched?: boolean;
    width?: number;
    onBlur: (event: { target: { id: string } }) => void;
}

const DateTimePicker: React.FC<Props> = ({
    error,
    id,
    label,
    name,
    onBlur,
    onChange,
    showTimeSelect,
    touched,
    width,
    ...rest
}) => {
    const { theme } = useTheme();

    return (
        <ReactDatePicker
            customInput={
                <Input
                    error={error}
                    id={id}
                    label={label}
                    name={name}
                    onChange={() => {}}
                    touched={touched}
                    width={width}
                />
            }
            dateFormat={showTimeSelect ? 'Pp' : 'P'}
            showPopperArrow={false}
            showTimeSelect={showTimeSelect}
            calendarClassName={theme === 'dark' ? 'calendar--dark-mode' : ''}
            onCalendarClose={() => onBlur({ target: { id } })}
            onSelect={onChange}
            onChange={onChange}
            {...rest}
        />
    );
};

export default DateTimePicker;
