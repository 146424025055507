import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Flex } from 'components/Layout';
import Modal from 'components/Modal';
import Button from 'components/Button';

interface Props {
    hide: () => void;
    handleConfirm: () => void;
    show: boolean;
}

const modalRoot = document.getElementById('modal-root');

const ConfirmationModal: React.FC<Props> = ({ hide, handleConfirm, show }) => {
    const el = document.createElement('div');

    useEffect(() => {
        if (modalRoot !== null && !modalRoot.hasChildNodes()) {
            modalRoot.appendChild(el);

            return () => {
                modalRoot.removeChild(el);
            };
        }
    }, [el]);

    return createPortal(
        <Modal header="Are you sure?" hide={hide} show={show}>
            <Flex margin={[20, 0, 0]}>
                <Button
                    appearance="secondary"
                    type="submit"
                    onClick={hide}
                    style={{ marginRight: 20 }}
                >
                    Cancel
                </Button>
                <Button
                    appearance="primary"
                    type="submit"
                    onClick={handleConfirm}
                >
                    Remove Status
                </Button>
            </Flex>
        </Modal>,
        el
    );
};

export default ConfirmationModal;
