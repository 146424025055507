import React, { useContext, FunctionComponent, useState } from 'react';
import { IGoldblumContext, IGoldblumState } from './types';

const initialState: IGoldblumState = {
    enabled: false,
};

const initialContext: IGoldblumContext = {
    ...initialState,
    toggleGoldblum: () => {},
};

const GoldblumContext = React.createContext(initialContext);
const GoldblumProvider: FunctionComponent = ({ children }) => {
    const [goldblumState, setGoldblumState] = useState(initialState);

    return (
        <GoldblumContext.Provider
            value={{
                ...goldblumState,
                toggleGoldblum: () =>
                    setGoldblumState({ enabled: !goldblumState.enabled }),
            }}
        >
            {children}
        </GoldblumContext.Provider>
    );
};

export default GoldblumProvider;

export const useGoldblum = () => useContext(GoldblumContext);
