import React, { SFC, useState } from 'react';
import { isToday, isWithinInterval, startOfToday } from 'date-fns';

import { useStatuses } from 'providers/Statuses';
import Section from '../Section';
import { IconButton } from 'components/Icon';
import { Flex } from 'components/Layout';
import errorAsset from '../../assets/error.svg';
import EmptyState from '../EmptyState';
import StatusesList from './components/StatusesList';
import StatusesOverview from './components/StatusesOverview';
import noStatusesAsset from 'routes/Dashboard/assets/noStatuses.svg';
import Celebrations from './components/Celebrations';
import CreateStatusForm from './components/CreateStatusForm';

const Pingboard: SFC = () => {
    const { statuses, error, hasResolved } = useStatuses();
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [filteredStatusTypes, setFilteredStatusTypes] = useState<string[]>(
        []
    );

    const handleFilteredStatusTypesChange = (
        newFilteredStatusTypes: string[]
    ) => {
        setFilteredStatusTypes(newFilteredStatusTypes);
    };

    const statusesForToday = statuses.filter(status => {
        const dateInterval = {
            start: new Date(status.starts_at),
            end: new Date(status.ends_at),
        };
        return (
            isToday(new Date(status.starts_at)) ||
            isWithinInterval(startOfToday(), dateInterval)
        );
    });

    const renderContent = () => {
        if (!statuses.length) {
            return (
                <EmptyState
                    label="Looks like everyone is busy at work."
                    image={noStatusesAsset}
                />
            );
        }
        return (
            <>
                <CreateStatusForm
                    show={showCreate}
                    hide={() => setShowCreate(false)}
                />
                {!filteredStatusTypes.length && <Celebrations />}
                <StatusesList
                    statusesForToday={statusesForToday}
                    filteredStatusTypes={filteredStatusTypes}
                />
            </>
        );
    };

    return (
        <Section
            label="Updates"
            controls={
                <Flex>
                    <IconButton
                        onClick={() => {
                            setShowCreate(true);
                        }}
                        name="add"
                        size={22}
                    />
                    <IconButton
                        onClick={() =>
                            window.open(
                                'https://dialexa.pingboard.com/',
                                '_blank'
                            )
                        }
                        name="launch"
                        size={22}
                    />
                </Flex>
            }
            filters={
                <StatusesOverview
                    statusesForToday={statusesForToday}
                    filteredStatusTypes={filteredStatusTypes}
                    onFilteredStatusTypesChange={
                        handleFilteredStatusTypesChange
                    }
                />
            }
        >
            {hasResolved && error && (
                <EmptyState label={error} image={errorAsset} />
            )}
            {hasResolved && !error && renderContent()}
        </Section>
    );
};

export default Pingboard;
