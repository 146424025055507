import React, {
    useContext,
    FunctionComponent,
    useState,
    useEffect,
} from 'react';
import { IVisibilityContext, IVisibilityState } from './types';

const initialState: IVisibilityState = {
    visible: false,
};

const initialContext: IVisibilityContext = {
    visible: initialState.visible,
    toggleVisibility: () => {},
};

const VisibilityContext = React.createContext(initialContext);

const VisibilityProvider: FunctionComponent = ({ children }) => {
    const [visible, setVisible] = useState(initialState.visible);

    useEffect(() => {
        const pulseVisibility = localStorage.getItem('pulseVisibility');
        if (pulseVisibility === null) {
            localStorage.setItem('pulseVisibility', 'show');
        }
        setVisible(
            pulseVisibility === null ? true : pulseVisibility === 'show'
        );
    }, []);

    const handleToggleVisibility = () => {
        localStorage.setItem('pulseVisibility', visible ? 'hidden' : 'show');
        setVisible(!visible);
    };

    return (
        <VisibilityContext.Provider
            value={{
                visible,
                toggleVisibility: handleToggleVisibility,
            }}
        >
            {children}
        </VisibilityContext.Provider>
    );
};

export default VisibilityProvider;

export const useVisibility = () => useContext(VisibilityContext);
