import React, { useRef, useEffect, useState } from 'react';

import {
    SectionWrapper,
    Scrollable,
    ContentWrapper,
    Header,
    Content,
    SectionTitle,
    TitleWrapper,
    FiltersWrapper,
    ControlsWrapper,
} from './styled';

interface SectionProps {
    label: string;
    children: React.ReactNode;
    controls?: React.ReactNode;
    filters?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
    label,
    children,
    controls,
    filters,
}) => {
    const scrollEl = useRef<HTMLDivElement>(null);
    const [hasScrolledFromTop, setHasScrolledFromTop] = useState(false);

    useEffect(() => {
        if (scrollEl.current) {
            scrollEl.current.onscroll = () => onScroll();
        }
    });

    const onScroll = () => {
        if (scrollEl.current && scrollEl.current.scrollTop > 0) {
            setHasScrolledFromTop(true);
        } else {
            setHasScrolledFromTop(false);
        }
    };

    return (
        <SectionWrapper>
            <Scrollable ref={scrollEl}>
                <ContentWrapper>
                    <Header hasShadow={hasScrolledFromTop}>
                        <TitleWrapper>
                            <SectionTitle>{label}</SectionTitle>
                            <ControlsWrapper>{controls}</ControlsWrapper>
                        </TitleWrapper>
                        {filters && <FiltersWrapper>{filters}</FiltersWrapper>}
                    </Header>
                    <Content>{children}</Content>
                </ContentWrapper>
            </Scrollable>
        </SectionWrapper>
    );
};

export default Section;
