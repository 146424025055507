import React from 'react';

import { Flex } from 'components/Layout';
import Typography from 'components/Typography';
import { Image } from './styled';

interface EmptyStateProps {
    image: string;
    label: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ image, label }) => {
    return (
        <Flex justify="center" padding={[40, 0, 0, 0]}>
            <Image src={image} />
            <Typography align="center" variant="small">
                {label}
            </Typography>
        </Flex>
    );
};

export default EmptyState;
