import { IAuthState, AuthAction } from './types';
import {
    AUTH_LOGIN_START,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_ERROR,
    AUTH_LOGOUT,
} from './constants';

export const initialState = {
    isAuthenticated: false,
    isLoading: false,
    hasResolved: false,
    error: '',
    idToken: '',
    accessToken: '',
    firebaseConfig: {},
};

export default (state: IAuthState, action: AuthAction) => {
    switch (action.type) {
        case AUTH_LOGIN_START:
            return {
                ...state,
                isLoading: true,
            };
        case AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasResolved: true,
                isAuthenticated: true,
                user: action.user,
                idToken: action.idToken,
                accessToken: action.accessToken,
            };
        case AUTH_LOGIN_ERROR:
            return {
                ...state,
                isLoading: false,
                hasResolved: true,
                error: action.message,
            };
        case AUTH_LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                idToken: '',
                accessToken: '',
            };
        default:
            throw new Error();
    }
};
