// firebase config
export const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const location = process.env.REACT_APP_FIREBASE_LOCATION;
export const messagingSenderId = process.env.REACT_APP_FIREBASE_SENDER_ID;
export const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;

// gapi config
export const clientId = process.env.REACT_APP_GAPI_CLIENT_ID;
export const discoveryDocs = [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
export const scope =
    'email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly';
