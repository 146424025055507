import styled, { css } from 'styled-components';
import { darken } from 'polished';
import theme from 'styled-theming';

import { IconButtonProps } from './components/IconButton';
import { transition, color } from 'constants/style';
import { themedBackgroundHover, themedRipple } from 'constants/theme';

const themedHover = theme('mode', {
    light: darken(0.06, darken(0.45, color.GRAY)),
    dark: color.WHITE,
});

const themedFill = theme('mode', {
    light: darken(0.45, color.GRAY),
    dark: color.WHITE,
});

const appearance = (props: IconButtonProps) => {
    if (props.disabled) return css``;
    return css`
        &:hover svg path {
            fill: ${props.color ? darken(0.06, props.color) : themedHover};
        }
    `;
};

export const Svg = styled.svg`
    display: inline-flex;
    vertical-align: middle;

    ${props =>
        css`
            path {
                fill: ${props.color || themedFill};
            }
        `};
`;

export const ButtonWrapper = styled.button`
    position: relative;
    height: 100%;
    background: transparent;
    border: none;
    outline: 0;
    display: inline-flex;
    padding: 8px;
    cursor: pointer;

    ${props =>
        props.disabled &&
        css`
            opacity: 0.5;
        `};
    ${appearance}

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: scale(0);
        background-color: ${themedBackgroundHover};
        z-index: 1;
        border-radius: 50%;
        transition: 0.1s ${transition.CURVE} all;
    }

    &:disabled {
        cursor: not-allowed;
    }

    ${props =>
        !props.disabled &&
        css`
            &:hover,
            &:focus {
                &:before {
                    transform: scale(1);
                }
            }

            &:active {
                &:before {
                    background-color: ${themedRipple};
                }
            }
        `};

    svg {
        z-index: 1;
    }
`;
