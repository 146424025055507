import styled, { css } from 'styled-components';

import { transition } from 'constants/style';
import {
    themedColorAlt,
    themedBorder,
    themedBoxShadowHover,
    themedBoxElevation,
    themedElevatedBackground,
    themedElevatedBackgroundAlt,
    themedElevatedBackgroundHover,
} from 'constants/theme';

interface SearchInputProps {
    hasScrolledFromTop: boolean;
    hasDropdown: boolean;
}

export const Wrapper = styled.div`
    position: relative;
    height: 50px;
    width: 100%;
`;

export const SearchInput = styled.input`
    display: block;
    height: 50px;
    width: 100%;
    color: ${themedColorAlt};
    font-family: inherit;
    font-size: 19px;
    line-height: 19px;
    font-weight: 600;
    padding: 0 30px;
    box-shadow: none;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;

    &:focus {
        ${(props: SearchInputProps) =>
            props.hasDropdown &&
            css`
                border-bottom: 1px solid ${themedBorder};
            `}
    }

    ${(props: SearchInputProps) =>
        props.hasScrolledFromTop &&
        css`
            box-shadow: ${themedBoxShadowHover};
        `}
`;

export const SearchContainer = styled.label`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 50px;
    background: ${themedElevatedBackgroundAlt};
    border-radius: 25px;
    transition: 0.1s ${transition.CURVE} all;
    overflow: hidden;
    z-index: 200;

    ${(props: { isFocused: boolean }) =>
        props.isFocused &&
        css`
            background: ${themedElevatedBackground};
            box-shadow: ${themedBoxElevation};
            border-radius: 25px;
        `}
`;

export const SearchItem = styled.a`
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 17px;
    border-bottom: 1px solid ${themedBorder};
    padding: 0 24px;
    color: ${themedColorAlt};

    &:hover,
    &:focus {
        color: ${themedColorAlt};
        outline: 0;
    }

    ${(props: { isFocused?: boolean }) =>
        props.isFocused &&
        css`
            color: ${themedColorAlt};
            background: ${themedElevatedBackgroundHover};
        `}

    &:last-child {
        border-color: transparent;
    }
`;

export const ResultsWrapper = styled.div`
    max-height: 360px;
    overflow: scroll;
`;
