import styled from 'styled-components';
import { themedColor } from 'constants/theme';
import { color } from 'constants/style';

export const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${(props: { margin?: string }) =>
        props.margin ? `margin: ${props.margin}` : ''}
`;

export const ErrorMessage = styled.p`
  color: ${color.RED}
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
`;

export const Label = styled.label`
    color: ${themedColor};
    font-family: inherit;
    font-size: 19px;
    line-height: 19px;
    font-weight: 600;
    padding: 5px 0;
`;
