import { IStatusesState, StatusesAction } from './types';
import {
    STATUSES_GET_LOCAL_STORAGE,
    STATUSES_FETCH_SUCCESS,
    STATUSES_FETCH_ERROR,
    STATUSES_POST_ERROR,
    STATUSES_POST_START,
    STATUSES_POST_SUCCESS,
} from './constants';

export const initialState: IStatusesState = {
    awaitPost: false,
    hasResolved: false,
    error: '',
    postError: '',
    statuses: [],
    statusTypes: null,
    statusTypesById: null,
    usersById: null,
    usersBySlackId: null,
    users: [],
};

export default function(
    state: IStatusesState,
    action: StatusesAction
): IStatusesState {
    switch (action.type) {
        case STATUSES_GET_LOCAL_STORAGE:
            return {
                ...state,
                hasResolved: true,
                statuses: action.statuses,
                statusTypes: action.statusTypes,
                statusTypesById: action.statusTypesById,
                users: action.users,
                usersById: action.usersById,
                usersBySlackId: action.usersBySlackId,
            };
        case STATUSES_FETCH_SUCCESS:
            return {
                ...state,
                usersById: action.usersById,
                usersBySlackId: action.usersBySlackId,
                statuses: action.statuses,
                statusTypes: action.statusTypes,
                statusTypesById: action.statusTypesById,
                users: action.users,
                hasResolved: true,
            };
        case STATUSES_FETCH_ERROR:
            return {
                ...state,
                error: action.error,
                hasResolved: true,
            };
        case STATUSES_POST_START:
            return {
                ...state,
                error: '',
                awaitPost: true,
            };
        case STATUSES_POST_ERROR:
            return {
                ...state,
                error: action.error,
                awaitPost: false,
            };
        case STATUSES_POST_SUCCESS:
            return {
                ...state,
                statuses: action.statuses,
                awaitPost: false,
            };
        default:
            throw new Error();
    }
}
