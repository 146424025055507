import styled, { keyframes } from 'styled-components';
import { lighten } from 'polished';
import { Flex } from 'components/Layout';
import { color } from 'constants/style';
import { themedBackground } from 'constants/theme';

export const ThemedFlex = styled(Flex)`
    background: ${themedBackground};
`;

export const GoogleButton = styled.button`
    display: block;
    width: 100%;
    height: 60px;
    border-radius: 6px;
    border: 0;
    font-size: 16px;
    font-weight: 600;
    color: ${color.GOLD};
    background-color: transparent;
    border: 1px solid ${color.GOLD};
    cursor: pointer;
    transition: 0.15s ease all;

    &:hover {
        box-shadow: 0 5px 10px rgba(166, 139, 85, 0.1);
        background-color: rgba(166, 139, 85, 0.1);
    }

    &:active {
        border-color: ${lighten(0.1, color.GOLD)};
        color: ${lighten(0.1, color.GOLD)};
    }

    &:active,
    &:focus {
        outline: 0;
    }
`;

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(214, 176, 100, 0.35);
  }
  80% {
    box-shadow: 0 0 0 160px rgba(214, 176, 100, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(214, 176, 100, 0);
  }
`;

export const Pulse = styled.div`
    position: absolute;
    margin: auto;
    height: 10px;
    width: 10px;
    border-radius: 100px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    animation: ${pulseAnimation} 2s infinite;

    &:after {
        content: '';
        position: absolute;
        height: 40px;
        width: 68px;
        background: ${themedBackground};
        margin: auto;
        top: 0;
        bottom: -15px;
        right: 0;
        left: -29px;
        z-index: -1;
    }
`;

// `
// @for $i from 0 through 20
//     #{percentage($i*(1/$steps))}
//         clip: rect(random(150)+px, 350px, random(150)+px, 30px)
// `;

// const randNum = (max: number = 150) => {
// 	return Math.floor(Math.random() * (150 - 1 + 1)) + 1;
// };

// const prop = () => {
// 	return `clip: rect(${randNum()}px, 350px, ${randNum()}px, 30px)`;
// };

// const glitch = (steps: number = 20) => {
// 	let styles = '';

// 	for (let i = 0; i <= 20; i++) {
// 		styles += `${i * (1 / steps) * 100}% { ${prop()} }`;
// 	}

// 	return css`
// 		${styles};
// 	`;
// };

// const glitchAnimation1 = keyframes`
//     ${glitch()}
// `;

// const glitchAnimation2 = keyframes`
//     ${glitch()}
// `;

// export const Glitch = styled.h1`
// 	color: #d6b064;
// 	font-family: sans-serif;
// 	font-weight: 800;
// 	position: relative;
// 	font-size: 100px;
// 	padding: 20px;

// 	&:before,
// 	&:after {
// 		padding: 30px;
// 		color: #d6b064;
// 		content: 'Dialexa';
// 		position: absolute;
// 		width: 120%;
// 		height: 55%;
// 		background: ${themedBackground};
// 		top: 0;
// 	}

// 	&:before {
// 		left: -50px;
// 		text-shadow: -2px 0 red;
// 		animation: ${glitchAnimation1} 15s linear 3s infinite;
// 	}

// 	&:after {
// 		left: -53px;
// 		text-shadow: -2px 0 blue;
// 		animation: ${glitchAnimation2} 3s linear 1s infinite;
// 	}
// `;
