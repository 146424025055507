import styled, { css } from 'styled-components';
import {
    themedBorder,
    themedBackground,
    themedBackgroundHover,
    themedBackgroundActive,
} from 'constants/theme';
import { Flex } from 'components/Layout';
import { color, transition } from 'constants/style';

export const SectionDivider = styled.div`
    height: 1px;
    border: 1px solid ${themedBorder};
    margin: 19px 0;
`;

export const StatusBubble = styled.div`
    width: 28px;
    height: 25px;
    min-width: 28px;
    min-height: 25px;
    text-align: center;
    line-height: 25px;
    margin-right: 8px;
    border-radius: 12.5px;
    color: ${color.WHITE};
    background: ${(props: { color: string }) => css`
        ${props.color || 'left'}
    `};
`;

export const StatusFilterWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 12px 0;
    cursor: pointer;
    padding: 6px;
    border-radius: 12px;
    border: none;
    outline: none;
    font-size: 14px;
    background-color: ${(props: { isSelected: boolean }) => css`
        ${props.isSelected ? themedBackgroundActive : 'transparent'}
    `};
    &:hover {
        background-color: ${themedBackgroundHover};
    }
`;

export const StatusesOverviewWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 10px 0;
    position: sticky;
    top: 0;
    z-index: 999;
    background: ${themedBackground};
`;

export const StatusFlex = styled(Flex)`
    margin-bottom: 30px;
    animation: fade-in 1.2s ${transition.CURVE} both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const CelebrationFlex = styled(Flex)`
    margin-bottom: 10px;
    animation: fade-in 1.2s ${transition.CURVE} both;

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;
