import React, { FunctionComponent, useState } from 'react';
import { StatusBubble, StatusFilterWrapper } from '../styled';
import Typography from 'components/Typography';

interface Props {
    statusId: string;
    bubbleColor: string;
    count: number;
    type: string;
    onClick: (id: string, isSelected: boolean) => void;
}
export const StatusFilter: FunctionComponent<Props> = ({
    statusId,
    bubbleColor,
    count,
    type,
    onClick,
}) => {
    const [isSelected, setIsSelected] = useState(false);

    const handleClick = () => {
        onClick(statusId, !isSelected);
        setIsSelected(!isSelected);
    };

    return (
        <StatusFilterWrapper isSelected={isSelected} onClick={handleClick}>
            <StatusBubble color={bubbleColor}>{count}</StatusBubble>
            <Typography
                style={{ marginBottom: 0, fontSize: 'unset' }}
                variant="description"
            >
                {type}
            </Typography>
        </StatusFilterWrapper>
    );
};
