import React from 'react';
import { StyledTextArea } from './styled';
import { ErrorMessage, FieldWrapper, Label } from '../styled';

interface Props {
    cols?: number;
    error?: string;
    id: string;
    label?: string;
    name: string;
    onChange: (
        eventOrPath: string | React.ChangeEvent<any>
    ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
    onBlur?: (eventOrString: any) => void | ((e: any) => void);
    placeholder?: string;
    rows?: number;
    touched?: boolean;
    value: any;
}

const TextArea: React.FC<Props> = ({
    cols,
    error,
    id,
    label,
    name,
    onChange,
    onBlur,
    placeholder,
    rows,
    touched,
    value,
}) => {
    return (
        <FieldWrapper>
            <Label htmlFor={id}>{label || name}</Label>
            <StyledTextArea
                cols={cols}
                id={id}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                rows={rows}
                value={value}
            />
            {error && touched && <ErrorMessage>{error}</ErrorMessage>}
        </FieldWrapper>
    );
};

export default TextArea;
