import React from 'react';

import {
    SectionContainer,
    Title,
    SectionOptions,
    Option,
    Switch,
    TilesContainer,
    Tile,
} from '../styled/Settings';

// Todo: rename checkbox to toggle
type SectionVariant = 'checkbox' | 'tiles';

interface Tile {
    label: string;
    value: any;
}

interface SectionProps2 {
    title: string;
    flavorText?: string;
    variant: SectionVariant;
    tiles?: Tile[];
    activeTileValue?: any;
    onChange?: (v: any) => void;
    onToggle?: () => void;
    isToggeledOn?: boolean;
}

const SettingsSection: React.FC<SectionProps2> = ({
    title,
    flavorText,
    variant,
    tiles,
    onChange,
    onToggle,
    isToggeledOn = false,
    activeTileValue = '',
}) => {
    return (
        <SectionContainer>
            <SectionOptions>
                <Title>{title}</Title>
                {variant === 'checkbox' && (
                    <Switch
                        type="checkbox"
                        onChange={() => onToggle && onToggle()}
                        checked={isToggeledOn}
                    ></Switch>
                )}
                {variant === 'tiles' && tiles && (
                    <TilesContainer>
                        {tiles.map(({ label, value }, idx) => (
                            <Tile
                                key={idx}
                                onClick={() => {
                                    onChange && onChange(value);
                                }}
                                isActive={value === activeTileValue}
                            >
                                {label}
                            </Tile>
                        ))}
                    </TilesContainer>
                )}
            </SectionOptions>
            {flavorText && <Option>{flavorText}</Option>}
        </SectionContainer>
    );
};

export default SettingsSection;
