import axios from 'axios';
import {
    location,
    projectId,
    scope,
    apiKey,
    messagingSenderId,
} from '../constants';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';

export const config = {
    apiKey,
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com`,
    messagingSenderId,
    projectId,
    storageBucket: `${projectId}.appspot.com`,
};

firebase.initializeApp(config);

firebase.functions();

axios.defaults.baseURL = `https://${location}-${projectId}.cloudfunctions.net`;

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

googleAuthProvider.addScope(scope);

export default firebase;
