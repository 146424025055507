import React, { SFC } from 'react';

import { useAuth } from 'providers/Auth';
import Login from './Login';
import Dashboard from './Dashboard';

const Routes: SFC = () => {
  const { hasResolved, isAuthenticated } = useAuth();

  if (hasResolved && isAuthenticated) {
    return <Dashboard />;
  } else if (hasResolved && !isAuthenticated) {
    return <Login />;
  } else {
    return <div />;
  }
}

export default Routes;
