import React from 'react';
import { BackDrop, ContentBox, ActionBar, CloseButton } from './styled';
import { ErrorMessage } from '../FormField/styled';
import { IconButton } from '../Icon';
import Typography from '../Typography';

interface Props {
    actions?: React.ReactNode;
    children: React.ReactNode;
    header: string;
    hide: () => void;
    modalError?: string;
    show: boolean;
}

const Modal: React.FC<Props> = ({
    actions,
    children,
    header,
    hide,
    modalError,
    show,
}) => {
    if (show) {
        return (
            <>
                <BackDrop onClick={hide}></BackDrop>
                <ContentBox>
                    <CloseButton>
                        <IconButton
                            type="button"
                            onClick={() => hide()}
                            name="close"
                            size={22}
                        />
                    </CloseButton>
                    <Typography variant="label">
                        <h2>{header}</h2>
                    </Typography>
                    {children}
                    {actions && <ActionBar>{actions}</ActionBar>}
                    {modalError && (
                        <ErrorMessage
                            style={{ textAlign: 'end', marginTop: '5px' }}
                        >
                            {modalError}
                        </ErrorMessage>
                    )}
                </ContentBox>
            </>
        );
    }
    return null;
};

export default Modal;
