import React from 'react';

import Navbar from 'components/Navbar';
import { Container, Box, Flex } from 'components/Layout';
import Icon from 'components/Icon';
import { color } from 'constants/style';

import Calendar from './components/Calendar';
import Pingboard from './components/Pingboard';
import Search from './components/Search';
import { Feedback, Link, Wrapper, HiddenModeWrapper } from './styled';
import { useVisibility } from 'providers/Visibility';
// import TenThousandFeet from './components/TenThousandFeet';



const Dashboard: React.FC = () => {

    const { visible } = useVisibility();
    return (
        <Wrapper>
            <Navbar />
            <Container
                fill
                direction="column"
                style={{ width: '100%', maxWidth: '1250px' }}
            >
                {visible ? (
                    <>
                        <Container style={{ width: '100%', maxWidth: '750px' }}>
                            <Box padding={[40, 0, 0, 0]} fill>
                                <Search />
                            </Box>
                        </Container>
                        <Flex
                            fill
                            align="stretch"
                            padding={[10, 0, 30, 0]}
                        >
                            <Calendar />
                            <Pingboard />
                            {/* <TenThousandFeet /> */}
                        </Flex>
                    </>
                ) : (
                    <HiddenModeWrapper>
                        <Icon
                            name="dialexa_robot"
                            color={color.GOLD}
                            size={100}
                            width={200}
                        />
                    </HiddenModeWrapper>
                )}
            </Container>
            <Feedback>
                <Link href="https://forms.gle/a3RoGfFX3aQabCMt6">Feedback</Link>
            </Feedback>
        </Wrapper>
    );
};

export default Dashboard;
