import React from 'react';

import Modal from 'components/Modal';
import { Flex } from 'components/Layout';
import SettingsSection from './SettingsSection';
import { SettingsContainer } from '../styled/Settings';
import { useGoldblum } from 'providers/Goldblum';
import { useTimeFormat, TimeFormat } from 'providers/TimeFormat';
import { useShowAllDayEvents } from 'providers/ShowAllDayEvents';
import { useTheme, ThemeOption } from 'providers/Theme';

export interface SettingsProps {
    show: boolean;
    hide: () => void;
}

const SettingsModal: React.FC<SettingsProps> = ({ hide, show }) => {
    const { enabled: isGoldblumEnabled, toggleGoldblum } = useGoldblum();
    const { format, setFormat } = useTimeFormat();
    const { showAllDayEvents, toggleShowAllDayEvents } = useShowAllDayEvents();

    const { setTheme } = useTheme();

    return (
        <Modal header="Settings" show={show} hide={hide}>
            <Flex>
                <SettingsContainer>
                    <SettingsSection
                        title="Clock Display"
                        flavorText="Set the display format"
                        onChange={(v: TimeFormat) => setFormat(v)}
                        variant="tiles"
                        activeTileValue={format}
                        tiles={[
                            { label: '12 hour', value: 'h:mm a' },
                            { label: '24 hour', value: 'H:mm' },
                        ]}
                    ></SettingsSection>
                    <SettingsSection
                        title="Theme"
                        flavorText="Set your theme preference"
                        activeTileValue={localStorage.getItem('pulseTheme')}
                        onChange={(v: ThemeOption) => setTheme(v)}
                        variant="tiles"
                        tiles={[
                            { label: 'Light', value: 'light' },
                            { label: 'Dark', value: 'dark' },
                            { label: 'Auto', value: 'auto' },
                        ]}
                    ></SettingsSection>
                    <SettingsSection
                        title="Goldblum"
                        flavorText={`Change all icons to Mr. Goldblum himself. \nYes, really.`}
                        onToggle={() => toggleGoldblum()}
                        isToggeledOn={isGoldblumEnabled}
                        variant="checkbox"
                    ></SettingsSection>

                    <SettingsSection
                        title="Show Day Events"
                        flavorText="Set display for all day events"
                        onToggle={() => toggleShowAllDayEvents()}
                        isToggeledOn={showAllDayEvents}
                        variant="checkbox"
                    ></SettingsSection>
                </SettingsContainer>
            </Flex>
        </Modal>
    );
};

export default SettingsModal;
