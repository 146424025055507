import styled from 'styled-components';

export const ClockContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
export const ClockDisplay = styled.div<{ size: number }>`
    cursor: default;
    font-size: ${props => props.size}px;
    line-height: 1;
    width: auto;
    position: relative;
`;

export const DateContainer = styled.div`
    font-weight: 600;
    font-size: 14px;
    font-family: Arial;
    height: 59px;
`;

export const DateWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 37px;
    padding: 6px;
`;
