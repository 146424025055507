import React, { SFC } from 'react';

import { useEvents } from 'providers/Events';
import { IEvent } from 'providers/Events/types';
import Section from '../Section';
import Event from './components/Event';
import { IconButton } from 'components/Icon';
import errorAsset from '../../assets/error.svg';
import noEventsAsset from '../../assets/noEvents.svg';
import EmptyState from '../EmptyState';
import { TodaysDate } from 'components/Clock'
import { Flex, Box } from 'components/Layout';
import PopOver, { MenuItem } from 'components/PopOver';

const Calendar: SFC = () => {
  const { events, error, hasResolved } = useEvents();

  const createMeetingInvite = () => {
    window.open('https://calendar.google.com/calendar/u/0/r/eventedit', '_blank');
  }

  const startGoogleMeet = () => {
    window.open('http://meet.google.com/new', '_blank');
  }

  const getStatus = (event: IEvent) => {
    if (!event.attendees || !event.attendees.length) {
      return 'accepted';
    }
    const self = event.attendees.find(attendee => attendee.self);
    if (self) return self.responseStatus
  }

  return (
    <Section
      label="Today's Schedule"
      controls={
        <Flex>
          <PopOver
            width={200}
            trigger={<IconButton
              onClick={() => {

              }}
              name="add"
              size={22}
            />}
          >
            <Box
              radius={5}
              elevation
              style={{ overflow: 'hidden' }}
            >
              <MenuItem onClick={createMeetingInvite}>Create Meeting Invite</MenuItem>
              <MenuItem onClick={startGoogleMeet}>Start New Google Meet</MenuItem>
            </Box>
          </PopOver>
          <IconButton onClick={() => window.open('https://calendar.google.com/', '_blank')} name="launch" size={22} />
        </Flex>
      }
    >
      <TodaysDate />
      {hasResolved && error ? (
        <EmptyState label={error} image={errorAsset} />
      ) : hasResolved && events.length > 0 ? events.map(event => (
        <Event
          key={event.id}
          id={event.id}
          attendees={event.attendees}
          name={event.summary}
          location={event.location}
          link={event.htmlLink}
          startTime={event.start && event.start.dateTime}
          endTime={event.end && event.end.dateTime}
          conferenceData={event.conferenceData}
          status={getStatus(event)}
        />
      )) : hasResolved ? (
        <EmptyState label="No more events today." image={noEventsAsset} />
      ) : (
              <div />
            )}
    </Section>
  );
};

export default Calendar;
