import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { useTimeFormat } from 'providers/TimeFormat';

import {
    ClockContainer,
    ClockDisplay,
    DateWrapper,
    DateContainer,
} from '../styled/Clock';

export const TodaysDate = () => {
    const today: Date = new Date();

    return (
        <DateContainer>
            <DateWrapper>{format(today, 'E L/d')}</DateWrapper>
        </DateContainer>
    );
};
interface SizeProps {
    /**px value of clock display text */
    size: number;
}

export const Clock: React.FC<SizeProps> = ({ size = 15 }) => {
    const [date, setDate] = useState(new Date());
    const { format: timeFormat } = useTimeFormat();

    useEffect(() => {
        const seconds = window.setInterval(() => {
            countUp();
        }, 1000);
        return () => window.clearInterval(seconds);
    }, [date]);

    const countUp = () => {
        setDate(new Date());
    };

    return (
        <ClockContainer>
            <ClockDisplay size={size}>{format(date, timeFormat)}</ClockDisplay>
        </ClockContainer>
    );
};
