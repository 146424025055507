import * as yup from 'yup';

export const createStatusSchema = yup.object().shape({
    message: yup.string(),
    status_type_id: yup.string().required('Status type is required'),
    time_period: yup
        .string()
        .oneOf([
            'this_morning',
            'this_afternoon',
            'today',
            'tomorrow',
            'another_time',
            'an_hour',
        ])
        .required('Duration is required'),
    all_day: yup.boolean(),
    starts_at: yup
        .string()
        .nullable()
        .when('time_period', {
            is: 'another_time' || 'an_hour',
            then: yup.string().required('Start is a required field'),
        }),
    ends_at: yup
        .string()
        .nullable()
        .when('time_period', {
            is: 'another_time' || 'an_hour',
            then: yup.string().required('End is a required field'),
        }),
});
