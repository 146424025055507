import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ErrorMessage, FieldWrapper, Label } from '../styled';
import {
    SelectValue,
    SelectWrapper,
    OptionsWrapper,
    OptionItem,
    ShowMore,
    ShowLess,
} from './styled';

export interface Option {
    disabled?: boolean;
    id: any;
    name: string;
    value?: any;
    [key: string]: any;
}

interface Props {
    disabled?: boolean;
    error?: string;
    id: string;
    label?: string;
    margin?: string;
    name: string;
    onBlur?: (eventOrString: any) => void | ((e: any) => void);
    onChange: (value: any) => void;
    options: Option[] | null;
    placeholder?: string;
    returnValue: 'full' | 'id' | 'value';
    touched?: boolean;
}

const Select: React.FC<Props> = ({
    disabled,
    error,
    id,
    label,
    margin,
    name,
    onBlur,
    onChange,
    options,
    placeholder,
    returnValue,
    touched,
}) => {
    const selectWrapper = useRef<HTMLDivElement>(null);
    const inputField = useRef<HTMLDivElement>(null);
    const [optionsOpened, setOptionsOpened] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);

    const handleOnBlur = useCallback(() => {
        if (onBlur) {
            onBlur({
                target: {
                    id,
                },
            });
        }
    }, [onBlur, id]);

    useEffect(() => {
        if (optionsOpened) {
            window.addEventListener('click', handleClickAway);
            return () => {
                window.removeEventListener('click', handleClickAway);
            };
        } else {
            handleOnBlur();
        }
    }, [optionsOpened, handleOnBlur]);

    function handleSelect(option: Option) {
        handleOnBlur();
        setSelectedOption(option);
        switch (returnValue) {
            case 'full':
                return onChange(option);
            case 'id':
                return onChange(option.id);
            case 'value':
                return onChange(option.value);
            default:
                return onChange(option);
        }
    }

    function handleClickAway(event: MouseEvent) {
        if (
            event.target !== selectWrapper.current &&
            event.target !== inputField.current
        ) {
            setOptionsOpened(false);
        }
    }

    function handleKeyDown(
        event: React.KeyboardEvent<HTMLLIElement>,
        option: Option
    ) {
        if (event.keyCode === 32 || event.keyCode === 13) {
            handleSelect(option);
            setOptionsOpened(false);
        }
    }

    function handleOnBlurEvent(e: any) {
        if (
            e.relatedTarget &&
            (!e.relatedTarget.id || e.target.id !== e.relatedTarget.id)
        ) {
            setOptionsOpened(false);
        }
    }

    return (
        <FieldWrapper margin={margin}>
            <Label htmlFor={id}>{label || name}</Label>
            <SelectWrapper
                disabled={disabled}
                opened={optionsOpened}
                id={id}
                ref={selectWrapper}
                onClick={() => setOptionsOpened(!optionsOpened)}
                onBlur={handleOnBlurEvent}
            >
                <SelectValue
                    opened={optionsOpened}
                    ref={inputField}
                    onFocus={() => setOptionsOpened(true)}
                >
                    {selectedOption ? selectedOption.name : placeholder}
                    {optionsOpened ? <ShowLess /> : <ShowMore />}
                    <OptionsWrapper opened={optionsOpened} id={id}>
                        {!!options &&
                            !!options.length &&
                            options.map((option, index) => (
                                <OptionItem
                                    tabIndex={option.disabled ? -1 : 0}
                                    disabled={option.disabled}
                                    id={id}
                                    key={option.id}
                                    onClick={e => {
                                        e.preventDefault();
                                        handleSelect(option);
                                    }}
                                    onKeyDown={e => handleKeyDown(e, option)}
                                >
                                    {option.name}
                                </OptionItem>
                            ))}
                    </OptionsWrapper>
                </SelectValue>
            </SelectWrapper>
            {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        </FieldWrapper>
    );
};

export default Select;
