import React, { SFC } from 'react';

import AuthProvider from './Auth';
import EventsProvider from './Events';
import StatusesProvider from './Statuses';
import SlackProvider from './Slack';
import ResourcesProvider from './Resources';
import ThemeProvider from './Theme';
import GoldblumProvider from './Goldblum';
import VisibilityProvider from './Visibility';
import TenThousandFeetProvider from './TenThousandFeet';
import TimeFormatProvider from './TimeFormat';
import ShowAllDayEventsProvider from './ShowAllDayEvents';

const Providers: SFC = ({ children }) => (
    <VisibilityProvider>
        <GoldblumProvider>
            <TimeFormatProvider>
                <AuthProvider>
                    <ShowAllDayEventsProvider>
                        <EventsProvider>
                            <StatusesProvider>
                                <SlackProvider>
                                    <ResourcesProvider>
                                        <TenThousandFeetProvider>
                                            <ThemeProvider>
                                                {children}
                                            </ThemeProvider>
                                        </TenThousandFeetProvider>
                                    </ResourcesProvider>
                                </SlackProvider>
                            </StatusesProvider>
                        </EventsProvider>
                    </ShowAllDayEventsProvider>
                </AuthProvider>
            </TimeFormatProvider>
        </GoldblumProvider>
    </VisibilityProvider>
);

export default Providers;
