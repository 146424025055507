import styled, { css } from 'styled-components';

import { transition } from 'constants/style';
import { themedColor, themedBackground, themedGradient } from 'constants/theme';
import { Box } from 'components/Layout';

export const SectionWrapper = styled.div`
    display: flex;
    flex: 1 0 auto;
    position: relative;
    margin: 60px 18px 0px;

    &:after {
        position: absolute;
        content: '';
        pointer-events: none;
        bottom: 0;
        left: 0;
        right: 0;
        height: 80px;
        background: ${themedGradient};
    }
    &:first-child {
        margin-left: 0px;
    }
    &:last-child {
        margin-right: 0px;
    }
`;

export const Scrollable = styled.div`
    position: relative;
    overflow: auto;
    flex: 1 0 auto;
    min-width: 365px;
`;

export const ContentWrapper = styled.div`
    background: ${themedBackground};
    flex: 1;
    position: absolute;
    width: 100%;
    padding: 0 10px;
`;

export const Header = styled.div`
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: ${themedBackground};
    color: ${themedColor};
    border-bottom: 1px solid transparent;

    &:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 0;
        z-index: 0;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
        opacity: 0;
        transition: 0.1s opacity ${transition.CURVE};

        ${(props: { hasShadow: boolean }) =>
            props.hasShadow &&
            css`
                opacity: 1;
            `}
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${themedBackground};
        z-index: 1;
    }
`;

export const SectionTitle = styled.h2`
    font-size: 24px;
    letter-spacing: 0.369231px;
    color: ${themedColor};
    font-weight: 600;
    flex: 1 0 auto;
    margin-bottom: 15px;
`;

export const TitleWrapper = styled.div`
    flex: 1;
    z-index: 2;
    display: flex;
`;

export const FiltersWrapper = styled.div`
    flex: 1 0 100%
    z-index: 2;
`;

export const Content = styled.div`
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
`;

export const ContentInner = styled.div`
    margin-top: 6px;
`;

export const ControlsWrapper = styled(Box)`
    height: 100%;
    margin: 0 -8px 0 0;
`;
