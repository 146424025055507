import styled, { css } from 'styled-components';

import { Box, BoxProps } from './Box';

export interface FlexProps extends BoxProps {
    inline?: boolean;
    align?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
    justify?: 'space-between' | 'center' | 'space-around';
    direction?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
    gutter?: number;
    noWrap?: boolean;
    grow?: number;
    width?: number;
    minWidth?: number;
}

export const Flex = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${(props: FlexProps) =>
      props.noWrap &&
      css`
          flex-wrap: nowrap;
      `}

  ${(props: FlexProps) =>
      props.inline &&
      css`
          display: inline-flex;
      `}

  ${(props: FlexProps) =>
      props.direction &&
      css`
          flex-direction: ${props.direction};
      `}

  ${(props: FlexProps) =>
      props.align &&
      css`
          align-items: ${props.align};
      `}

  ${(props: FlexProps) =>
      props.justify &&
      css`
          justify-content: ${props.justify};
      `}

  ${(props: FlexProps) =>
      props.grow &&
      css`
          flex-grow: ${props.grow};
      `}

  ${(props: FlexProps) =>
      props.minWidth &&
      css`
          min-width: ${props.minWidth}px;
      `}

  ${(props: FlexProps) =>
      props.gutter &&
      css`
          margin-right: -${props.gutter}px;
          margin-left: -${props.gutter}px;

          > * {
              margin-left: ${props.gutter}px;
              margin-right: ${props.gutter}px;
          }
      `}
`;
