// TODO: Needs lots of clean up
import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    FunctionComponent,
    useCallback,
} from 'react';
import axios from 'axios';

import { useAuth } from '../Auth';
import { IMessageType, ISlackContext, ISlackState } from './types';

const initialState: ISlackState = {
    hasResolved: false,
    error: '',
    announcements: [],
};

const initialContext: ISlackContext = {
    ...initialState,
    fetch: () => {},
};

const SlackContext = createContext(initialContext);

const SlackProvider: FunctionComponent = ({ children }) => {
    const { idToken, firebaseConfig } = useAuth();
    const [announcements, setAnnouncements] = useState(
        initialState.announcements
    );
    const [hasResolved, setHasResolved] = useState(initialState.hasResolved);
    const [error, setError] = useState(initialState.error);

    const fetch = useCallback(() => {
        axios
            .get('/slack', firebaseConfig)
            .then(res => {
                res.data.messages.sort((a: IMessageType, b: IMessageType) => {
                    return b.creation_date - a.creation_date;
                });
                setAnnouncements(res.data.messages);
                localStorage.setItem(
                    'announcements',
                    JSON.stringify(res.data.messages)
                );
                setHasResolved(true);
            })
            .catch(err => {
                setError(err.message);
                setHasResolved(true);
            });
    }, [firebaseConfig]);

    useEffect(() => {
        const localAnnouncements = JSON.parse(
            localStorage.getItem('announcements')!
        );

        if (localAnnouncements && !hasResolved) {
            setAnnouncements(localAnnouncements);
            setHasResolved(true);
        }
    }, [hasResolved]);

    useEffect(() => {
        if (idToken) {
            fetch();
        }
    }, [idToken, fetch]);

    return (
        <SlackContext.Provider
            value={{
                fetch,
                error,
                hasResolved,
                announcements,
            }}
        >
            {children}
        </SlackContext.Provider>
    );
};

export default SlackProvider;

export const useSlack = () => useContext(SlackContext);
