import styled from 'styled-components';
import { themedColorAlt, themedElevatedBackgroundAlt } from 'constants/theme';
import { color } from 'constants/style';

export const StyledTextArea = styled.textarea`
    color: ${themedColorAlt};
    font-family: inherit;
    font-size: 19px;
    line-height: 19px;
    font-weight: 600;
    padding: 15px;
    box-shadow: none;
    border: none;
    outline: none;
    background: ${themedElevatedBackgroundAlt};
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    border-radius: 3px;
    resize: none;
    &:focus {
        padding: 15px 14px;
        border: 1px solid ${color.GOLD_LIGHT};
    }
`;
