import styled from 'styled-components';
import { themedElevatedBackgroundAlt, themedColor } from 'constants/theme';
import { color } from 'constants/style';

export const Checkmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: -1;
    border-radius: 3px;
    background-color: ${color.GOLD};
`;
export const StyledCheckbox = styled.input`
    height: 24px;
    width: 25px;
    margin: 0;
    appearance: none;
    border-radius: 3px;
    outline: none;
    background-color: ${themedElevatedBackgroundAlt};
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    &:checked {
        background-color: transparent;
    }
    &:focus {
        border: 1px solid ${color.GOLD_LIGHT};
    }
`;

export const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 10px;
`;
export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 25px;
    ${(props: { margin?: string }) =>
        props.margin ? `margin: ${props.margin}` : ''}
`;

export const CheckboxLabel = styled.label`
    color: ${themedColor};
    font-family: inherit;
    font-size: 19px;
    font-weight: 600;
    align-self: center;
    height: 25px;
`;
