export const durationOptions = [
    {
        id: 'an_hour',
        name: 'An Hour',
    },
    {
        id: 'this_morning',
        name: 'This Morning',
    },
    {
        id: 'this_afternoon',
        name: 'This Afternoon',
    },
    {
        id: 'today',
        name: 'Today',
    },
    {
        id: 'tomorrow',
        name: 'Tomorrow',
    },
    {
        id: 'another_time',
        name: 'Other Duration',
    },
];
