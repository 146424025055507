import React, { useState, useContext } from 'react';

const FORMAT_STORAGE_KEY = 'pulseShowAllDayEvents';

type ShowAllDayEvents = 'true' | 'false';

interface ShowAllDayEventsContext {
    showAllDayEvents: boolean;
    toggleShowAllDayEvents: () => void;
}

const ShowAllDayEventsContext = React.createContext(
    {} as ShowAllDayEventsContext
);

const ShowAllDayEventsProvider: React.FC = ({ children }) => {
    const defaultValue = 'true' as ShowAllDayEvents;
    const [showAllDayEvents, setShowAllDayEvents] = useState<boolean>(true);

    React.useEffect(() => {
        const localFormat = localStorage.getItem(
            FORMAT_STORAGE_KEY
        ) as ShowAllDayEvents;
        setEventFormat(localFormat || defaultValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setEventFormat = (value: ShowAllDayEvents) => {
        localStorage.setItem(FORMAT_STORAGE_KEY, value);
        setShowAllDayEvents(value === 'true');
    };

    const toggleShowAllDayEvents = () => {
        setEventFormat(showAllDayEvents ? 'false' : 'true');
    };

    return (
        <ShowAllDayEventsContext.Provider
            value={{ showAllDayEvents, toggleShowAllDayEvents }}
        >
            {children}
        </ShowAllDayEventsContext.Provider>
    );
};

export default ShowAllDayEventsProvider;

export const useShowAllDayEvents = () => useContext(ShowAllDayEventsContext);
