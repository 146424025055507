import { Dispatch } from 'react';

import { apiKey, clientId, scope, discoveryDocs } from '../../constants';
import firebase from '../firebase';

import {
    AUTH_LOGIN_START,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_ERROR,
    AUTH_LOGOUT,
} from './constants';
import { AuthAction } from './types';

const handleIsSignedIn = async (
    dispatch: Dispatch<AuthAction>,
    isSignedIn: boolean,
    auth2: gapi.auth2.GoogleAuth
) => {
    try {
        if (!isSignedIn) {
            throw new Error('Not logged in.');
        }

        const {
            id_token,
            access_token,
        } = auth2.currentUser.get().getAuthResponse();

        const credential = firebase.auth.GoogleAuthProvider.credential(
            id_token,
            access_token
        );

        const user =
            firebase.auth().currentUser ||
            (await firebase.auth().signInWithCredential(credential)).user;

        if (!user) {
            throw new Error('Unable to find user.');
        }

        const idToken = await user.getIdToken();

        dispatch({
            type: AUTH_LOGIN_SUCCESS,
            user,
            idToken,
            accessToken: access_token,
        });
    } catch (e) {
        dispatch({
            type: AUTH_LOGIN_ERROR,
            message: e.message,
        });
    }
};

const initGapi = (dispatch: Dispatch<AuthAction>) => async () => {
    await gapi.client.init({ apiKey, clientId, scope, discoveryDocs });

    const auth2 = gapi.auth2.getAuthInstance();
    auth2.isSignedIn.listen(isSignedIn =>
        handleIsSignedIn(dispatch, isSignedIn, auth2)
    );
    handleIsSignedIn(dispatch, auth2.isSignedIn.get(), auth2);
};

export const refresh = (dispatch: Dispatch<AuthAction>) => {
    try {
        gapi.load('client:auth2', initGapi(dispatch));
    } catch (e) {
        console.log(`Google Auth ERROR: ${e}`);
    }
};

export const login = (dispatch: Dispatch<AuthAction>) => {
    dispatch({ type: AUTH_LOGIN_START });
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signIn();
};

export const logout = (dispatch: Dispatch<AuthAction>) => {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch({ type: AUTH_LOGOUT });
            });
    });
};
