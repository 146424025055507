import React, {
    useEffect,
    useReducer,
    createContext,
    useContext,
    FunctionComponent,
} from 'react';

import { useAuth } from '../Auth';

import { getLocalStorageData, fetch, createStatus, removeStatus } from './actions';
import reducer, { initialState } from './reducer';
import { ICreateStatusPayload, IStatusesContext } from './types';

const initialContext: IStatusesContext = {
    ...initialState,
    createStatus: (payload: ICreateStatusPayload) => {},
    removeStatus: (statusId: string) => {},
    fetch: () => {},
};

const StatusesContext = createContext(initialContext);

const StatusesProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { idToken, firebaseConfig } = useAuth();

    useEffect(() => {
        getLocalStorageData(dispatch, state.hasResolved);
    }, [state.hasResolved]);

    useEffect(() => {
        if (idToken) {
            fetch(dispatch, firebaseConfig);
        }
    }, [idToken, firebaseConfig]);

    return (
        <StatusesContext.Provider
            value={{
                ...state,
                createStatus: (data: ICreateStatusPayload) => createStatus(dispatch, firebaseConfig, data),
                removeStatus: (statusId: string) => removeStatus(dispatch, firebaseConfig, statusId),
                fetch: () => fetch(dispatch, firebaseConfig),
            }}
        >
            {children}
        </StatusesContext.Provider>
    );
};

export default StatusesProvider;

export const useStatuses = () => useContext(StatusesContext);
