import React, { createContext, useContext } from 'react';
import { ThemeProvider as ThemeProviderComponent } from 'styled-components';

const THEME_STORAGE_KEY = 'pulseTheme';
// new theme_default
const THEME_DEFAULT = 'auto';
const THEMES_ARRAY = ['dark', 'light', 'auto'] ;
export type ThemeOption = 'dark' | 'light' | 'auto';
export type Theme = 'dark' | 'light';

export interface ThemeContext {
    theme: Theme; // note: previously called mode
    setTheme: (t: ThemeOption) => void;
}

const themeContext = createContext({} as ThemeContext);
const osPreferenceMql =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
const isSystemDark = () => osPreferenceMql.matches;
const getSystemTheme = () => (isSystemDark() ? 'dark' : 'light');

const ThemeProvider: React.FC = ({ children }) => {
    const [theme, _setTheme] = React.useState<Theme>('light');
    const [selection, setSelection] = React.useState<ThemeOption>('light');

    React.useEffect(() => {
        const localMode = localStorage.getItem(
            THEME_STORAGE_KEY
        ) as ThemeOption;
        THEMES_ARRAY.includes(localMode) ? setTheme(localMode) : setTheme(THEME_DEFAULT)
    }, []);

    React.useEffect(() => {
        const listener = () => {
            setTheme('auto');
        };
        if (selection === 'auto') {
            osPreferenceMql.addEventListener('change', listener);
        }
        return () => {
            osPreferenceMql.removeEventListener('change', listener);
        };
    }, [selection]);

    const setTheme = (t: ThemeOption) => {
        const adjustedTheme = t === 'auto' ? getSystemTheme() : t;
        _setTheme(adjustedTheme);
        setSelection(t);
        localStorage.setItem(THEME_STORAGE_KEY, t);
    };

    return (
        <themeContext.Provider value={{ theme, setTheme }}>
            <ThemeProviderComponent theme={{ mode: theme }}>
                {children}
            </ThemeProviderComponent>
        </themeContext.Provider>
    );
};

export default ThemeProvider;

export const useTheme = () => useContext(themeContext);
