// TODO: Needs lots of clean up
import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    FunctionComponent,
    useCallback,
} from 'react';
import axios from 'axios';

import { useStatuses } from '../Statuses';
import { useAuth } from '../Auth';
import { IResourcesState, IResourcesContext } from './types';

const initialState: IResourcesState = {
    hasResolved: false,
    error: '',
    resources: [],
};

const initialContext: IResourcesContext = {
    ...initialState,
    fetch: () => {},
};

const ResourcesContext = createContext(initialContext);

const ResourcesProvider: FunctionComponent = ({ children }) => {
    const { idToken, firebaseConfig } = useAuth();
    const { users } = useStatuses();
    const [resources, setResources] = useState(initialState.resources);
    const [hasResolved, setHasResolved] = useState(initialState.hasResolved);
    const [error, setError] = useState(initialState.error);

    const fetch = useCallback(() => {
        axios
            .get('/resources', firebaseConfig)
            .then(res => {
                setResources(res.data.items.concat(users));
                localStorage.setItem(
                    'resources',
                    JSON.stringify(res.data.items)
                );
                setHasResolved(true);
            })
            .catch(err => {
                setError(err.message);
                setHasResolved(true);
            });
    }, [firebaseConfig, users]);

    useEffect(() => {
        const localResources = localStorage.getItem('resources')!;
        const parsedResources = JSON.parse(localResources);

        if (parsedResources && !hasResolved) {
            setResources(parsedResources);
            setHasResolved(true);
        }
    }, [hasResolved]);

    useEffect(() => {
        if (idToken && users) {
            fetch();
        }
    }, [idToken, users, fetch]);

    return (
        <ResourcesContext.Provider
            value={{
                fetch,
                error,
                hasResolved,
                resources,
            }}
        >
            {children}
        </ResourcesContext.Provider>
    );
};

export default ResourcesProvider;

export const useResources = () => useContext(ResourcesContext);
