import React from 'react';
import { ErrorMessage } from '../styled';
import {
    CheckboxContainer,
    StyledCheckbox,
    Checkmark,
    CheckboxWrapper,
    CheckboxLabel,
} from './styled';
import Icon from 'components/Icon';
import { color } from 'constants/style';

interface Props {
    error?: string;
    id: string;
    label?: string;
    name: string;
    margin?: string;
    onBlur: (e: React.FocusEvent<any>) => void;
    onChange: (
        eventOrPath: string | React.ChangeEvent<any>
    ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
    touched?: boolean;
    value: boolean;
}

const Checkbox: React.FC<Props> = ({
    error,
    id,
    label,
    margin,
    name,
    onBlur,
    onChange,
    touched,
    value,
}) => {
    return (
        <>
            <CheckboxWrapper margin={margin}>
                <CheckboxContainer>
                    <StyledCheckbox
                        type="checkbox"
                        name={name}
                        id={id}
                        checked={value}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                    <Checkmark>
                        <Icon name="check" color={color.WHITE} />
                    </Checkmark>
                </CheckboxContainer>
                <CheckboxLabel htmlFor={id}>{label || name}</CheckboxLabel>
            </CheckboxWrapper>
            {error && touched && <ErrorMessage>{error}</ErrorMessage>}
        </>
    );
};

export default Checkbox;
