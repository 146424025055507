import { lighten, darken } from 'polished';

/**
 * Style constants should always follow the Dialexa Brand Guidelines.
 * https://company-172457.frontify.com/d/scbHNDmrpzRG/guidelines#/basics/colors
 */
const dialexaGold = '#D6B064';
export const color = {
    GOLD: dialexaGold,
    /** Modified golds only for interactive uses (ie. button hover) */
    GOLD_LIGHT: lighten(0.04, dialexaGold),
    GOLD_DARK: darken(0.04, dialexaGold),
    BLACK: 'rgb(32, 33, 36)',
    BLACK_MED: '#333438',
    BLACK_MED_ALT: '#333438',
    BLACK_LIGHT: '#CfCfCf',
    WHITE: '#FFFFFF',
    GRAY: '#D6D7D9',
    GRAY_LIGHT: '#e6e8eb',
    GRAY_DARK: '#D3C5C7',
    GREEN: '#98CE80',
    GREEN_LIGHT: '#3FC0A7',
    BLUE_LIGHT: '#5DC6E8',
    YELLOW_DARK: '#EFBA3C',
    RED: '#F0553D',
    RED_LIGHT: '#F5846F',
    PURPLE: '#9676A7',
};

export const font = {
    BASE: [
        'Aaux Next',
        'Avenir Next',
        '-apple-system',
        'BlinkMacSystemFont',
        'Helvetica',
        'Arial',
        'sans-serif',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
    ].join(),
    CODE: ['Menlo', 'Consolas', 'Monaco'].join(),
};

export const textSize = {
    BASE: '15px',
    SMALL: '12px',
};

export const transition = {
    CURVE: 'cubic-bezier(0.4, 0, 0.2, 1)',
};
