import React, {
    useState,
    useRef,
    useEffect,
    cloneElement,
    useCallback,
} from 'react';
import { disableScroll, enableScroll } from '../helpers';

export interface PopOverProps {
    children: React.ReactElement<any>;
    trigger: React.ReactElement<any>;
    offset?: number;
    height?: number;
    width?: number;
    bottomRight?: boolean;
}

const PopOver: React.FC<PopOverProps> = ({
    children,
    trigger,
    offset = 8,
    width,
    bottomRight,
}) => {
    const triggerWrapper = useRef<HTMLDivElement>(null);
    const popOverWrapper = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [positionResolved, setPositionResolved] = useState(false);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);

    const updatePosition = useCallback(() => {
        if (triggerWrapper.current && popOverWrapper.current) {
            const triggerTop = triggerWrapper.current.getBoundingClientRect()
                .top;
            const triggerLeft = triggerWrapper.current.getBoundingClientRect()
                .left;
            const triggerWidth = triggerWrapper.current.clientWidth;
            const triggerHeight = triggerWrapper.current.clientHeight;
            const popOverWidth = popOverWrapper.current.clientWidth;

            if (
                triggerLeft -
                    popOverWidth / 2 +
                    triggerWidth / 2 +
                    popOverWidth >
                window.innerWidth
            ) {
                setLeft(window.innerWidth - (popOverWidth + 20));
            } else {
                // TODO: remove bottom right prop and incorporate more intelligent
                //       'anchor' prop.
                if (bottomRight) {
                    setLeft(triggerLeft + triggerWidth - popOverWidth);
                } else {
                    setLeft(triggerLeft - popOverWidth / 2 + triggerWidth / 2);
                }
            }

            setTop(triggerTop + triggerHeight + offset);

            setPositionResolved(true);
        }
    }, [triggerWrapper, popOverWrapper, bottomRight, offset]);

    useEffect(() => {
        updatePosition();
        if (isOpen) {
            window.addEventListener('click', handleClickAway);
            window.addEventListener('resize', updatePosition);
            disableScroll();
            return () => {
                window.removeEventListener('click', handleClickAway);
                window.removeEventListener('resize', updatePosition);
                enableScroll();
            };
        }
    }, [isOpen, updatePosition]);

    const handleClickAway = (event: MouseEvent) => {
        if (event.target !== popOverWrapper.current) {
            setIsOpen(false);
            setPositionResolved(true);
        }
    };

    const renderTrigger = () =>
        cloneElement(trigger, {
            onClick: () => setIsOpen(!isOpen),
        });

    return (
        <>
            <div ref={triggerWrapper}>{renderTrigger()}</div>
            {isOpen && (
                <div
                    ref={popOverWrapper}
                    style={{
                        position: 'fixed',
                        zIndex: 1001,
                        opacity: positionResolved ? 1 : 0,
                        top,
                        left,
                        width,
                    }}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default PopOver;
