import React from 'react';

import { Flex, Box, BoxProps } from 'components/Layout';
import { AppsMenuWrapper, AppIcon, AppLabel } from '../styled/AppsMenu';
import apps from '../apps';

interface AppsMenuProps extends BoxProps {
    setShowSettings: (show: boolean) => void;
}

const AppsMenu: React.FC<AppsMenuProps> = props => {
    return (
        <AppsMenuWrapper {...props}>
            <Flex>
                {apps.map(app => (
                    <AppIcon href={app.url} key={app.label}>
                        <img
                            src={app.icon}
                            height="45px"
                            width="45px"
                            alt="Pulse"
                        />
                        <Box margin={[-3, 0, 0]}>
                            <AppLabel>{app.label}</AppLabel>
                        </Box>
                    </AppIcon>
                ))}
            </Flex>
        </AppsMenuWrapper>
    );
};
export default AppsMenu;
