import { Dispatch } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import {
    STATUSES_GET_LOCAL_STORAGE,
    STATUSES_FETCH_SUCCESS,
    STATUSES_FETCH_ERROR,
    STATUSES_POST_START,
    STATUSES_POST_SUCCESS,
    STATUSES_POST_ERROR,
} from './constants';

import { StatusesAction, IStatus, ICreateStatusPayload } from './types';

export function getLocalStorageData(
    dispatch: Dispatch<StatusesAction>,
    hasResolved: boolean
) {
    const localStatuses = JSON.parse(localStorage.getItem('statuses')!);
    const localStatusTypes = JSON.parse(localStorage.getItem('statusTypes')!);
    const localStatusTypesById = JSON.parse(
        localStorage.getItem('statusTypesById')!
    );
    const localUsers = JSON.parse(localStorage.getItem('users')!);
    const localUsersById = JSON.parse(localStorage.getItem('usersById')!);
    const localUsersBySlackId = JSON.parse(
        localStorage.getItem('usersBySlackId')!
    );

    if (localStatuses && !hasResolved) {
        dispatch({
            type: STATUSES_GET_LOCAL_STORAGE,
            statuses: localStatuses,
            statusTypes: localStatusTypes,
            statusTypesById: localStatusTypesById,
            users: localUsers,
            usersById: localUsersById,
            usersBySlackId: localUsersBySlackId,
        });
    }
}

export async function fetch(
    dispatch: Dispatch<StatusesAction>,
    firebaseConfig: AxiosRequestConfig
) {
    try {
        const { pingboardUsers, statuses, statusTypes } = (
            await axios.get('/pingboard', firebaseConfig)
        ).data;

        const usersArr = Object.keys(pingboardUsers).map(key => {
            const user = pingboardUsers[key];
            return {
                birthDay: user.birth_date
                    ? user.birth_date.replace('0000', '1980')
                    : '',
                email: user.email,
                id: user.id,
                image: user.avatar_urls ? user.avatar_urls.small : '',
                startDate: user.start_date,
                tags: user.skills,
                title: `${user.first_name} ${user.last_name}`,
                type: 'user',
                url: `https://dialexa.pingboard.com/users/${user.id}`,
            };
        });

        const slackPingboardIdMap: {
            [name: string]: string;
        } = Object.keys(pingboardUsers).reduce(
            (acc, key) => ({
                ...acc,
                ...{
                    [key]: pingboardUsers[key].apps?.slack?.slack_id,
                },
            }),
            {}
        );

        const slackUsers: { [name: string]: IStatus } = Object.keys(
            pingboardUsers
        ).reduce(
            (acc, key) =>
                slackPingboardIdMap[key] !== undefined
                    ? {
                          ...acc,
                          ...{
                              [slackPingboardIdMap[key]]: pingboardUsers[key],
                          },
                      }
                    : {
                          ...acc,
                      },
            {}
        );

        const statusTypesArray = Object.keys(statusTypes).map(
            key => statusTypes[key]
        );

        localStorage.setItem('statuses', JSON.stringify(statuses));
        localStorage.setItem('statusTypes', JSON.stringify(statusTypesArray));
        localStorage.setItem('statusTypesById', JSON.stringify(statusTypes));
        localStorage.setItem('users', JSON.stringify(usersArr));
        localStorage.setItem('usersById', JSON.stringify(pingboardUsers));
        localStorage.setItem('usersBySlackId', JSON.stringify(slackUsers));

        dispatch({
            type: STATUSES_FETCH_SUCCESS,
            usersById: pingboardUsers,
            usersBySlackId: slackUsers,
            statuses: statuses,
            statusTypes: statusTypesArray,
            statusTypesById: statusTypes,
            users: usersArr,
        });
    } catch (e) {
        dispatch({
            type: STATUSES_FETCH_ERROR,
            error: e.message,
        });
    }
}

export async function createStatus(
    dispatch: Dispatch<StatusesAction>,
    firebaseConfig: AxiosRequestConfig,
    body: ICreateStatusPayload
) {
    dispatch({ type: STATUSES_POST_START });
    try {
        const { data } = await axios.post(
            '/pingboard/status',
            body,
            firebaseConfig
        );

        localStorage.setItem('statuses', JSON.stringify(data));

        dispatch({
            type: STATUSES_POST_SUCCESS,
            statuses: data,
        });
    } catch (e) {
        dispatch({
            type: STATUSES_POST_ERROR,
            error: e.message,
        });
    }
}

export async function removeStatus(
    dispatch: Dispatch<StatusesAction>,
    firebaseConfig: AxiosRequestConfig,
    statusId: string
) {
    dispatch({ type: STATUSES_POST_START });

    try {
        const { data } = await axios.delete(
            `/pingboard/status/${statusId}`,
            firebaseConfig
        );

        localStorage.setItem('statuses', JSON.stringify(data));

        dispatch({
            type: STATUSES_POST_SUCCESS,
            statuses: data,
        });
    } catch (e) {
        dispatch({
            type: STATUSES_POST_ERROR,
            error: e.message,
        });
    }
}
