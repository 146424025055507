import * as React from 'react';
import { isToday, startOfToday } from 'date-fns';
import { useStatuses } from 'providers/Statuses';
import SpecialEvent from './SpecialEvent';
import { CelebrationFlex } from '../styled';

const Celebrations: React.FC<{}> = () => {
    const { users } = useStatuses();
    const today = startOfToday();

    const isAnniversary = (dateStr: string) => {
        if (!dateStr) return false;
        const [year, month, day] = dateStr.split('-');
        const date = new Date(`${month}/${day}/${year}`);
        return (
            !isToday(date) &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()
        );
    };

    // Local State
    const birthdays = users.filter(user => isAnniversary(user.birthDay));
    const anniversaries = users.filter(user => isAnniversary(user.startDate));
    const firstDays = users.filter(user => isToday(new Date(user.startDate)));

    if (!birthdays.length && !firstDays.length && !anniversaries.length)
        return <></>;

    return (
        <CelebrationFlex align="center" margin={[0, 0, 10, 0]}>
            {birthdays.map((user, idx) => (
                <SpecialEvent key={idx} type="birthday" user={user} />
            ))}
            {anniversaries.map((user, idx) => (
                <SpecialEvent key={idx} type="anniversary" user={user} />
            ))}
            {firstDays.map((user, idx) => (
                <SpecialEvent key={idx} type="firstday" user={user} />
            ))}
        </CelebrationFlex>
    );
};

export default Celebrations;
