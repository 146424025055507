import styled, { css } from 'styled-components';

import { AvatarProps } from '../components/Avatar';
import {
    themedBackground,
    themedBackgroundActive,
    themedElevatedBackgroundHover,
    themedColor,
    themedBackgroundHover,
} from 'constants/theme';

const size = ({ readOnly, size, square }: AvatarProps) => {
    if (!size) return;
    const adjustedSize = readOnly ? size : square ? 6 : size + 8;
    return css`
        height: ${adjustedSize}px;
        width: ${adjustedSize}px;
        border-radius: ${adjustedSize}px;

        &:after {
            height: ${size}px;
            width: ${size}px;
            border-radius: ${square ? 6 : size}px;
        }
    `;
};

const background = ({ image }: AvatarProps) => css`
    background-image: ${image && `url(${image})`};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`;

const readOnly = ({ readOnly }: AvatarProps) =>
    readOnly
        ? css`
              background-color: transparent;
              cursor: default;
          `
        : css`
              cursor: pointer;
              background-color: ${themedBackground};
              &:hover {
                  background-color: ${themedElevatedBackgroundHover};
              }

              &:active {
                  background-color: ${themedBackgroundActive};
              }
          `;

export const StyledAvatar = styled.div`
    ${size};
    position: relative;
    background-color: ${themedBackground};
    margin-right: 12px;
    color: ${themedColor} ${readOnly};

    ${props =>
        props.onClick &&
        css`
            cursor: pointer;

            &:hover {
                background-color: ${themedBackgroundHover}:;
            }
        `};

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        ${background};
    }
`;
