import React, { useState } from 'react';

import { Flex, Box } from 'components/Layout';
import Icon, { IconButton } from 'components/Icon';
import { color } from 'constants/style';
import Avatar from 'components/Avatar';
import { useAuth } from 'providers/Auth';
import PopOver, { MenuItem } from 'components/PopOver';
import AppsMenu from './AppsMenu';
import { ThemedFlex } from '../styled/NavBar';
import { useVisibility } from 'providers/Visibility';
import { Clock } from 'components/Clock';
import SettingsModal from './Settings/components/SettingsModal';

const Navbar: React.FC = () => {
    const { user, isAuthenticated, logout } = useAuth();
    const { visible, toggleVisibility } = useVisibility();
    const [showSettings, setShowSettings] = useState(false);

    return (
        <>
            <ThemedFlex
                justify="space-between"
                align="center"
                padding={[12, 20]}
            >
                <Flex>
                    <Icon
                        name="pulse_logo"
                        viewBox="105 -5 24 48"
                        color={color.GOLD}
                        size={38}
                        width={200}
                    />
                </Flex>
                {isAuthenticated && (
                    <>
                        <SettingsModal
                            show={showSettings}
                            hide={() => setShowSettings(false)}
                        />
                        <Flex align="center" gutter={6}>
                            <Clock size={15} />
                            <IconButton
                                color={color.GOLD}
                                name={visible ? 'visible' : 'hidden'}
                                onClick={() => toggleVisibility()}
                            />
                            <PopOver
                                width={300}
                                trigger={
                                    <IconButton
                                        color={color.GOLD}
                                        name="apps"
                                        onClick={() => {}}
                                    />
                                }
                            >
                                <AppsMenu
                                    setShowSettings={setShowSettings}
                                    radius={5}
                                    elevation
                                    padding={20}
                                />
                            </PopOver>
                            <PopOver
                                width={180}
                                trigger={
                                    <Avatar image={user ? user.photoURL : ''} />
                                }
                            >
                                <Box
                                    radius={5}
                                    elevation
                                    style={{ overflow: 'hidden' }}
                                >
                                    <MenuItem
                                        onClick={() => setShowSettings(true)}
                                    >
                                        Settings
                                    </MenuItem>
                                    <MenuItem onClick={logout}>Logout</MenuItem>
                                </Box>
                            </PopOver>
                        </Flex>
                    </>
                )}
            </ThemedFlex>
        </>
    );
};
export default Navbar;
