import React, { SFC } from 'react';

import { StyledAvatar } from '../styled/Avatar';
import jeffImage from 'assets/jeff.png';
import { useGoldblum } from 'providers/Goldblum';
import placeholder from 'assets/placeholder.svg';
export interface AvatarProps {
    image: string | null;
    readOnly?: boolean;
    size?: number;
    square?: boolean;
    onClick?: () => void;
}

const Avatar: SFC<AvatarProps> = ({
    image,
    readOnly = false,
    size = 32,
    square = false,
    onClick,
}) => {
    const jeffContext = useGoldblum();
    image = jeffContext.enabled
        ? jeffImage
        : image === null
        ? placeholder
        : image;
    return (
        <StyledAvatar
            {...{ image, readOnly, size, square }}
            onClick={onClick}
        />
    );
};

export default Avatar;
