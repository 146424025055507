import styled from 'styled-components';
import { themedColorAlt, themedElevatedBackgroundAlt } from 'constants/theme';
import { color } from 'constants/style';

export const StyledInput = styled.input`
    color: ${themedColorAlt};
    font-family: inherit;
    font-size: 19px;
    line-height: 19px;
    font-weight: 600;
    padding: 10px;
    padding-left: 15px;
    box-shadow: none;
    border: none;
    outline: none;
    background: ${themedElevatedBackgroundAlt};
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    border-radius: 3px;
    &:focus {
        border: 1px solid ${color.GOLD_LIGHT};
    }
    ${(props: { width?: number }) =>
        props.width ? `width: ${props.width}px` : ''}
`;
