import * as React from 'react';
import { Text } from '../styled';
import { useTheme } from 'providers/Theme';

export interface Props {
    align?: 'center' | 'left' | 'right';
    children: React.ReactNode;
    variant: 'label' | 'description' | 'small';
    style?: any;
}

const Typography: React.FC<Props> = props => {
    const { align, children, style, variant } = props;
    const { theme } = useTheme();

    const getSubtleValue = () => {
        switch (variant) {
            case 'description':
                return theme === 'dark' ? 0.15 : 0.55;
            case 'small':
                return 0.35;
            case 'label':
                return undefined;
            default:
                return undefined;
        }
    };

    return (
        <Text style={style} align={align} subtle={getSubtleValue()}>
            {children}
        </Text>
    );
};

export default Typography;
