import dayjs from 'dayjs';

export const getDateRange = (
    startDate: string,
    endDate: string,
    allDay: string
) => {
    if (allDay) {
        if (dayjs(startDate).isSame(endDate, 'day')) {
            return `, ${dayjs(startDate).format('MMMM D')}`;
        } else {
            return `, ${dayjs(startDate).format('MMMM D')} - ${dayjs(
                endDate
            ).format('MMM D')}`;
        }
    } else {
        if (dayjs(startDate).isSame(endDate, 'day')) {
            return `, ${dayjs(startDate).format('MMMM D h:mma')} - ${dayjs(
                endDate
            ).format('h:mma')}`;
        } else {
            return `, ${dayjs(startDate).format('MMMM D h:mma')} - ${dayjs(
                endDate
            ).format('MMM D h:mma')}`;
        }
    }
};
