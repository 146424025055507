// TODO: Needs cleaned up and potential moved to use useReducer
import React, { useEffect, useState, useRef } from 'react';

import { Box } from 'components/Layout';
import { useResources } from 'providers/Resources';
import { useTheme } from 'providers/Theme';
import {
    SearchContainer,
    SearchInput,
    Wrapper,
    SearchItem,
    ResultsWrapper,
} from './styled';
import Avatar from 'components/Avatar';
import confluenceIcon from './assets/confluence.png';
import confluenceIconDark from './assets/confluence-darkmode.png';
import dropboxIcon from './assets/dropbox.png';
import dropboxIconDark from './assets/dropbox-darkmode.png';
import githubIcon from './assets/github.png';
import githubIconDark from './assets/github-darkmode.png';
import jiraIcon from './assets/jira.png';
import jiraIconDark from './assets/jira-darkmode.png';
import invisionIcon from './assets/invision.png';
import invisionIconDark from './assets/invision-darkmode.png';

const Search: React.FunctionComponent = () => {
    const inputEl = useRef<HTMLInputElement>(null);
    const resultsWrapper = useRef<HTMLDivElement>(null);
    const { theme } = useTheme();
    const { resources } = useResources();
    const [results, setResults] = useState<any[]>([]);
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState('');
    const [focusedResult, setFocusedResult] = useState(-1);
    const [hoverDisabled, setHoverDisabled] = useState(false);
    const [hasScrolledFromTop, setHasScrolledFromTop] = useState(false);
    const isDarkTheme = theme === 'dark';
    // Themed Icons
    const themedConfluenceIcon = isDarkTheme
        ? confluenceIconDark
        : confluenceIcon;
    const themedDropboxIcon = isDarkTheme ? dropboxIconDark : dropboxIcon;
    const themedGithubIcon = isDarkTheme ? githubIconDark : githubIcon;
    const themedJiraIcon = isDarkTheme ? jiraIconDark : jiraIcon;
    const themedInvisionIcon = isDarkTheme ? invisionIconDark : invisionIcon;

    useEffect(() => {
        window.addEventListener('mousemove', enableHover);
        window.addEventListener('keydown', disableArrowScroll);
        return () => {
            window.removeEventListener('mousemove', enableHover);
            window.addEventListener('keydown', disableArrowScroll);
        };
    });

    useEffect(() => {
        if (resultsWrapper.current) {
            resultsWrapper.current.onscroll = () => onScroll();
        }
    });

    const disableArrowScroll = (e: KeyboardEvent) => {
        if ([38, 40].indexOf(e.keyCode) > -1 && isFocused) {
            e.preventDefault();
        }
    };

    const onScroll = () => {
        if (resultsWrapper.current && resultsWrapper.current.scrollTop > 0) {
            setHasScrolledFromTop(true);
        } else {
            setHasScrolledFromTop(false);
        }
    };

    const enableHover = () => setHoverDisabled(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        filterResults(e.target.value);
        setFocusedResult(-1);
    };

    const filterResults = (q: string) => {
        if (q.length > 1) {
            const result = resources.filter(
                item =>
                    item.title &&
                    item.title.toLowerCase().includes(q.toLowerCase())
            );
            setResults(result);
        } else {
            setResults([]);
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const clickedTargetId =
            e.relatedTarget && (e.relatedTarget as HTMLDivElement).id;
        if (clickedTargetId && clickedTargetId.includes('queryResult')) {
            inputEl.current && inputEl.current.focus();
        } else {
            setIsFocused(false);
        }
    };

    const focusNextResult = () => {
        const nextIndex = focusedResult + 1;
        if (nextIndex <= results.length - 1) {
            setFocusedResult(nextIndex);
            setHoverDisabled(true);
            if (resultsWrapper.current) {
                const child = resultsWrapper.current.childNodes[
                    nextIndex
                ] as HTMLElement;
                child.focus();
            }
        }
        if (nextIndex > 5 && resultsWrapper.current) {
            const scrollPosition = (nextIndex - 5) * 60;
            resultsWrapper.current.scrollTo(0, scrollPosition);
        }
    };

    const focusPreviousResult = () => {
        const nextIndex = focusedResult - 1;
        if (nextIndex > -1) {
            setFocusedResult(nextIndex);
            setHoverDisabled(true);
            if (resultsWrapper.current) {
                if (resultsWrapper.current.scrollTop > nextIndex * 60) {
                    const scrollPosition = nextIndex * 60;
                    resultsWrapper.current.scrollTo(0, scrollPosition);
                }
            }
        }
    };

    const reset = () => {
        setValue('');
        setResults([]);
        inputEl.current && inputEl.current.blur();
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.keyCode) {
            case 40:
                focusNextResult();
                break;
            case 38:
                focusPreviousResult();
                break;
            case 13:
                window.location = results[focusedResult].url;
                break;
            case 27:
                reset();
                break;
            default:
                break;
        }
        if (inputEl.current) {
            inputEl.current.focus();
        }
    };

    return (
        <Wrapper>
            <SearchContainer isFocused={isFocused}>
                <SearchInput
                    ref={inputEl}
                    value={value}
                    hasDropdown={value.length > 1}
                    placeholder="Search people and resources"
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                    hasScrolledFromTop={hasScrolledFromTop}
                />

                {isFocused && results.length > 0 && (
                    <ResultsWrapper ref={resultsWrapper}>
                        {results.map((result, idx) => (
                            <SearchItem
                                href={result.url}
                                id={`queryResult-${idx}`}
                                key={`queryResult-${idx}`}
                                isFocused={focusedResult === idx}
                                onMouseEnter={() =>
                                    !hoverDisabled && setFocusedResult(idx)
                                }
                            >
                                {result.type === 'user' && result.image ? (
                                    <Avatar
                                        image={result.image}
                                        size={34}
                                        readOnly
                                    />
                                ) : result.type === 'confluence' ? (
                                    <Avatar
                                        image={themedConfluenceIcon}
                                        size={34}
                                        readOnly
                                    />
                                ) : result.type === 'dropbox' ? (
                                    <Avatar
                                        image={themedDropboxIcon}
                                        size={34}
                                        readOnly
                                    />
                                ) : result.type === 'github' ? (
                                    <Avatar
                                        image={themedGithubIcon}
                                        size={34}
                                        readOnly
                                    />
                                ) : result.type === 'jira' ? (
                                    <Avatar
                                        image={themedJiraIcon}
                                        size={34}
                                        readOnly
                                    />
                                ) : result.type === 'invision' ? (
                                    <Avatar
                                        image={themedInvisionIcon}
                                        size={34}
                                        readOnly
                                    />
                                ) : (
                                    <div />
                                )}
                                <Box fill padding={[2, 0, 0, 0]}>
                                    {result.title}
                                </Box>
                            </SearchItem>
                        ))}
                    </ResultsWrapper>
                )}
                {isFocused && results.length === 0 && value.length > 1 && (
                    <SearchItem>No results found</SearchItem>
                )}
            </SearchContainer>
        </Wrapper>
    );
};

export default Search;
