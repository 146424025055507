import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { useStatuses } from 'providers/Statuses';
import { Flex } from 'components/Layout';
import { useAuth } from 'providers/Auth';
import Modal from 'components/Modal';
import TextArea from 'components/FormField/TextArea';
import Select from 'components/FormField/Select';
import Checkbox from 'components/FormField/Checkbox';
import Button from 'components/Button';
import DateTimePicker from 'components/FormField/DateTimePicker';

import { createStatusSchema } from './validation';
import { durationOptions } from './constants';
import { usePrevious } from 'hooks';

const FORM_MARGIN = '10px 0';

interface Props {
    hide: () => void;
    show: boolean;
}

const CreateStatusForm: React.FC<Props> = ({ hide, show }) => {
    const {
        awaitPost,
        createStatus,
        postError,
        statusTypes,
        users,
    } = useStatuses();
    const { user } = useAuth();
    const [pingboardUserId, setPingboarUserId] = useState<number | null>(null);
    const [messagePlaceholder, setMessagePlaceholder] = useState<string>('');
    const previousAwaitPost = usePrevious(awaitPost);

    const formik = useFormik({
        initialValues: {
            message: '',
            status_type_id: '',
            time_period: '',
            all_day: false,
            starts_at: null,
            ends_at: null,
        },
        onSubmit: handleSubmit,
        validationSchema: createStatusSchema,
        validateOnMount: true,
    });

    useEffect(updatePingboarUserId, [user, users.length]);
    useEffect(clearForm, [show]);
    useEffect(handleCloseForm, [awaitPost]);

    function handleCloseForm() {
        if (previousAwaitPost && !awaitPost && !postError) {
            hide();
        }
    }

    function clearForm() {
        formik.resetForm();
    }

    function handleSubmit(values: any) {
        if (!pingboardUserId) {
            return;
        }
        const statuses = {
            ...values,
            user_id: pingboardUserId,
        };

        if (statuses.starts_at) {
            statuses.starts_at = new Date(values.starts_at).toISOString();
        }
        if (statuses.ends_at) {
            statuses.ends_at = new Date(values.ends_at).toISOString();
        }
        createStatus({ statuses });
    }

    function updatePingboarUserId() {
        if (user && users.length) {
            const pingboardUser = users.find(
                pingboardUser => pingboardUser.email === user.email
            );
            if (pingboardUser && pingboardUser.id) {
                setPingboarUserId(pingboardUser.id);
            }
        }
    }

    function handleStatusSelect(option: any) {
        formik.setFieldValue('status_type_id', option.id);
        formik.setFieldTouched('status_type_id');
        setMessagePlaceholder(option.placeholder);
    }

    function handleDurationSelect(val: string) {
        formik.setFieldValue(
            'time_period',
            val === 'an_hour' ? 'another_time' : val
        );
        formik.setFieldTouched('time_period');

        if (val === 'another_time' || val === 'an_hour') {
            let endTime = new Date();
            if (val === 'an_hour') {
                endTime.setHours(endTime.getHours() + 1);
            }
            if (!formik.values.starts_at) {
                formik.setFieldValue('starts_at', new Date());
            }
            if (!formik.values.ends_at) {
                formik.setFieldValue('ends_at', endTime);
            }
        } else {
            formik.setFieldValue('starts_at', '');
            formik.setFieldValue('ends_at', '');
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Modal
                actions={
                    <Button
                        appearance="primary"
                        disabled={!formik.isValid}
                        loading={awaitPost}
                        type="submit"
                    >
                        Submit
                    </Button>
                }
                header="Create Status"
                hide={hide}
                show={show}
                modalError={postError}
            >
                <Flex direction="column" margin={[20, 0, 10, 15]}>
                    <Select
                        error={formik.errors.status_type_id}
                        id="status_type_id"
                        label="Status*"
                        margin={FORM_MARGIN}
                        name="status_type_id"
                        onBlur={formik.handleBlur}
                        onChange={handleStatusSelect}
                        options={statusTypes}
                        placeholder="- Select a status -"
                        returnValue="full"
                        touched={formik.touched.status_type_id}
                    />
                    <Select
                        error={formik.errors.time_period}
                        id="time_period"
                        label="Duration*"
                        margin={FORM_MARGIN}
                        name="time_period"
                        onBlur={formik.handleBlur}
                        onChange={handleDurationSelect}
                        options={durationOptions}
                        placeholder="- How long will you be gone? -"
                        returnValue="id"
                        touched={formik.touched.time_period}
                    />
                    <Checkbox
                        error={formik.errors.all_day}
                        id="all_day"
                        label="All day"
                        margin={FORM_MARGIN}
                        name="all_day"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        touched={formik.touched.all_day}
                        value={formik.values.all_day}
                    />
                    {(formik.values.time_period === 'another_time' ||
                        formik.values.time_period === 'an_hour') && (
                        <>
                            <Flex justify="space-between">
                                <DateTimePicker
                                    error={formik.errors.starts_at}
                                    touched={formik.touched.starts_at}
                                    width={210}
                                    label="Start*"
                                    id="starts_at"
                                    name="starts_at"
                                    onBlur={formik.handleBlur}
                                    allowSameDay
                                    onChange={(date: Date) =>
                                        formik.setFieldValue('starts_at', date)
                                    }
                                    selectsStart
                                    selected={formik.values.starts_at}
                                    startDate={formik.values.starts_at}
                                    endDate={formik.values.ends_at}
                                    minDate={new Date()}
                                    showTimeSelect={!formik.values.all_day}
                                />
                                <DateTimePicker
                                    error={formik.errors.ends_at}
                                    touched={formik.touched.ends_at}
                                    width={210}
                                    label="End*"
                                    id="ends_at"
                                    name="ends_at"
                                    onBlur={formik.handleBlur}
                                    allowSameDay
                                    onChange={(date: Date) =>
                                        formik.setFieldValue('ends_at', date)
                                    }
                                    selectsEnd
                                    selected={formik.values.ends_at}
                                    startDate={formik.values.starts_at}
                                    endDate={formik.values.ends_at}
                                    minDate={formik.values.starts_at}
                                    showTimeSelect={!formik.values.all_day}
                                />
                            </Flex>
                        </>
                    )}
                    <TextArea
                        cols={50}
                        error={formik.errors.message}
                        id="message"
                        label="Message"
                        name="message"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder={messagePlaceholder}
                        rows={5}
                        touched={formik.touched.message}
                        value={formik.values.message}
                    />
                </Flex>
            </Modal>
        </form>
    );
};

export default CreateStatusForm;
