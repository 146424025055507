import styled from 'styled-components';
import { lighten, darken } from 'polished';

import { ButtonProps } from '../components/Button';
import { textSize, color, transition } from 'constants/style';

const appearance = (props: ButtonProps) => {
    switch (props.appearance) {
        case 'primary':
            return `
        background-color: ${color.GOLD};
        border-color: ${color.GOLD};
        color: ${color.WHITE};
        ${props.fullWidth ? 'width: 100%' : ''}
        ${props.width ? `width: ${props.width}px` : ''}

        svg path {
          fill: ${color.WHITE};
        }

        &:hover {
          background-color: ${color.GOLD_LIGHT};
          border-color: ${color.GOLD_LIGHT};
          box-shadow: 0 1px 1px 0 rgba(221, 185, 115, 0.45),
                      0 1px 3px 1px rgba(221, 185, 115, 0.3);
        }

        &:active {
          background-color: ${color.GOLD_DARK};
          border-color: ${color.GOLD_DARK};
        }

        &[disabled] {
          opacity: 0.4;
          box-shadow: none;

          svg path {
            fill: ${color.GRAY_DARK};
          }
        }
      `;
        case 'secondary':
            return `
          background-color: transparent;
          border-color: ${lighten(0.05, color.GRAY)};
          color: ${darken(0.05, color.GOLD)};
          ${props.fullWidth ? 'width: 100%' : ''}
          ${props.width ? `width: ${props.width}px` : ''}

          svg path {
            fill: ${color.GOLD};
          }

          &:hover {
            background-color: ${lighten(0.37, color.GOLD)};
            border-color: ${lighten(0.05, color.GRAY)};
          }

          &:active {
            background-color: ${lighten(0.34, color.GOLD)};
            border-color: ${lighten(0.05, color.GRAY)};
          }

          &[disabled] {
            background-color: transparent;
            border-color: transparent;
            color: ${color.GRAY};
            box-shadow: none;

            svg path {
              fill: ${color.GRAY};
            }
          }
        `;
        default:
            return `
        background-color: transparent;
        border-color: transparent;
        color: ${darken(0.05, color.GOLD)};
        ${props.fullWidth ? 'width: 100%' : ''}
        ${props.width ? `width: ${props.width}px` : ''}

        svg path {
          fill: ${darken(0.05, color.GOLD)};
        }

        &:hover {
          background-color: ${lighten(0.35, color.GOLD)};
          border-color: ${lighten(0.35, color.GOLD)};
        }

        &:active {
          background-color: ${lighten(0.32, color.GOLD)};
          border-color: ${lighten(0.35, color.GOLD)};
        }

        &[disabled] {
          background-color: transparent;
          border-color: transparent;
          color: ${color.GRAY};
          box-shadow: none;

          svg path {
            fill: ${color.GRAY};
          }
        }
      `;
    }
};

export default styled.button`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-style: normal;
    font-weight: 900;
    font-size: ${textSize.BASE};
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    outline: none;
    padding: 0 14px;
    height: 42px;
    line-height: 1;
    width: auto;
    max-width: 100%;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    transition: all 0.25s ${transition.CURVE};

    &[disabled] {
        cursor: not-allowed;
    }

    ${appearance}
`;
