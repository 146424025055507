import styled from 'styled-components';

import { Flex } from './Flex';

export const Fullscreen = styled(Flex)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
