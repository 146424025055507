import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { color as colorConstants } from 'constants/style';
import { themedBoxElevation } from 'constants/theme';
import { mapStyle } from '../../StyleBase';

export interface BoxProps {
    fill?: boolean;
    background?: string;
    padding?:
        | number
        | [number, number]
        | [number, number, number]
        | [number, number, number, number];
    margin?:
        | number
        | [number, number]
        | [number, number, number]
        | [number, number, number, number];
    radius?: number;
    elevation?: boolean;
    border?: boolean;
    color?: string;
    width?: number;
}

const color = ({ color }: BoxProps) => mapStyle('color', color);

const padding = ({ padding }: BoxProps) => mapStyle('padding', padding);

const margin = ({ margin }: BoxProps) => mapStyle('margin', margin);

const radius = ({ radius }: BoxProps) => mapStyle('border-radius', radius);

const background = ({ background }: BoxProps) =>
    mapStyle('background', background);

const elevation = ({ elevation }: BoxProps) =>
    elevation &&
    css`
        box-shadow: ${themedBoxElevation};
    `;

const fill = ({ fill }: BoxProps) =>
    fill &&
    css`
        flex: 1 1 0%;
    `;

const border = ({ border }: BoxProps) =>
    border &&
    css`
        border: 1px solid ${darken(0.07, colorConstants.GRAY_LIGHT)};
    `;

export const Box = styled.div<BoxProps>`
  ${fill}
  ${padding}
  ${margin}
  ${radius}
  ${background}
  ${elevation}
  ${border}
  ${color}
`;
