import calendarIcon from './assets/calendar.svg';
import confluenceIcon from './assets/confluence.svg';
import driveIcon from './assets/drive.svg';
import githubIcon from './assets/github.svg';
import gmailIcon from './assets/gmail.svg';
import jiraIcon from './assets/jira.svg';
import pingboardIcon from './assets/pingboard.svg';
import trinetIcon from './assets/trinet.svg';
import salesforceIcon from './assets/salesforce.svg';
import map from './assets/map.svg';
import meetings from './assets/meetings.svg';
import lattice from './assets/lattice.svg';
import testDevicesIcon from './assets/testDevices.svg';
import robot from './assets/robot.svg';
import betterworks from './assets/betterworks.svg';

export default [
    {
        label: 'Building Map',
        url:
            'https://dialexa.atlassian.net/wiki/spaces/ON/pages/317095946/Building+Map',
        icon: map,
    },
    {
        label: 'Calendar',
        url: 'https://calendar.google.com',
        icon: calendarIcon,
    },
    {
        label: 'Confluence',
        url: 'https://dialexa.atlassian.net/wiki/',
        icon: confluenceIcon,
    },
    {
        label: 'Drive',
        url: 'https://drive.google.com',
        icon: driveIcon,
    },
    {
        label: 'Github',
        url: 'https://github.com/dialexa',
        icon: githubIcon,
    },
    {
        label: 'Gmail',
        url: 'https://mail.google.com',
        icon: gmailIcon,
    },
    {
        label: 'Jira',
        url: 'https://dialexa.atlassian.net/secure/MyJiraHome.jspa',
        icon: jiraIcon,
    },
    {
        label: 'Lattice',
        url: 'https://dialexa.latticehq.com/',
        icon: lattice,
    },
    {
        label: 'Meetings',
        url: 'https://meetings.dialexa.com',
        icon: meetings,
    },
    {
        label: 'Pingboard',
        url: 'https://dialexa.pingboard.com/home',
        icon: pingboardIcon,
    },
    {
        label: 'Salesforce',
        url: 'https://dialexa.lightning.force.com',
        icon: salesforceIcon,
    },
    {
        label: 'Service Desk',
        url: 'https://dialexa.atlassian.net/servicedesk/customer/portals',
        icon: jiraIcon,
    },
    {
        label: 'Trinet',
        url: 'https://trinet.hrpassport.com',
        icon: trinetIcon,
    },

    {
        label: 'Test Devices',
        url:
            'https://docs.google.com/spreadsheets/d/1mVS0YJg0FBHLo6NP4Ak9zsMuoAihlZOmSiOtwkyifPI/edit?usp=sharing',
        icon: testDevicesIcon,
    },
    {
        label: 'Betterworks',
        url: 'https://app.betterworks.com/app/splash/#/signin',
        icon: betterworks,
    },
    {
        label: 'Branding Material',
        url: 'https://dialexa.atlassian.net/wiki/spaces/SALES/overview',
        icon: robot,
    },
];
