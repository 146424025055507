import styled, { keyframes } from 'styled-components';
import theme from 'styled-theming';
import { color } from 'constants/style';
import { Flex } from 'components/Layout/styled/Flex';

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const background = theme('mode', {
    light: color.WHITE,
    dark: color.BLACK,
});

export const Wrapper = styled.div`
    background: ${background};
    display: flex;
    flex-direction: column;
    flex: 1;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    animation: ${fade} 0.3s ease;
`;

export const Feedback = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 12px 20px 12px 20px;
`;

export const Link = styled.a``;

export const HiddenModeWrapper = styled(Flex)`
    height: 100%;
    justify-content: center;
    align-items: center;
`;
