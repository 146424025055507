import styled from 'styled-components';
import {
    themedColor,
    themedBorder,
    themedElevatedBackground,
    themedElevatedBackgroundHover,
} from 'constants/theme';

// TODO: remove themedBoarder
export const MenuItem = styled.a`
    display: block;
    border-bottom: 1px solid ${themedBorder};
    padding: 14px 20px;
    cursor: pointer;
    color: ${themedColor};
    background: ${themedElevatedBackground};

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        color: ${themedColor};
        background: ${themedElevatedBackgroundHover};
        border-color: ${themedElevatedBackgroundHover};
    }
`;
