import React, {
    useReducer,
    useEffect,
    createContext,
    useContext,
    FunctionComponent,
} from 'react';

import reducer, { initialState } from './reducer';
import { refresh, logout, login } from './actions';
import { IAuthContext } from './types';

const initialContext: IAuthContext = {
    ...initialState,
    login: () => {},
    logout: () => {},
};

const AuthContext = createContext(initialContext);

const AuthProvider: FunctionComponent = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        refresh(dispatch);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login: () => login(dispatch),
                logout: () => logout(dispatch),
                firebaseConfig: {
                    headers: {
                        Authorization: `Bearer ${state.idToken}`,
                        accessToken: state.accessToken,
                    },
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
