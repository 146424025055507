import React, { SFC } from 'react';

import paths from '../paths';
import { Svg } from '../styled';

export interface IconProps {
	name: string;
	color?: string;
	size?: number;
	width?: number;
	viewBox?: string;
}

const Icon: SFC<IconProps> = ({
	name,
	color,
	viewBox = '0 0 24 24',
	size = 24,
	width = 24
}) => (
	<Svg color={color} width={`${width}px`} height={`${size}px`} viewBox={viewBox}>
		{paths[name].map((path: string, idx: number) => <path d={path} key={`${name}-${idx}`} />)}
	</Svg>
);

export default Icon;
