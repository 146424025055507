import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    FunctionComponent,
    useCallback,
} from 'react';
import axios from 'axios';
import { startOfWeek } from 'date-fns';

import { useAuth } from '../Auth';
import {
    ITenThousandFeetContext,
    ITenThousandFeetState,
    Assignment,
} from './types';

const initialState: ITenThousandFeetState = {
    hasResolved: false,
    error: '',
    assignments: { current: [], future: [] },
};

const initialContext: ITenThousandFeetContext = {
    ...initialState,
    fetch: () => {},
};

const TenThousandFeetContext = createContext(initialContext);

const TenThousandFeetProvider: FunctionComponent = ({ children }) => {
    const { user, firebaseConfig } = useAuth();
    const [assignments, setAssignments] = useState(initialState.assignments);
    const [hasResolved, setHasResolved] = useState(initialState.hasResolved);
    const [error, setError] = useState(initialState.error);

    const fetch = useCallback(
        (email: string) => {
            axios
                .get(`/tenThousandFeet/${email}`, firebaseConfig)
                .then(res => {
                    const sortedAssignment = res.data.sort(
                        (a: Assignment, b: Assignment) =>
                            new Date(a.starts_at).valueOf() -
                            new Date(b.starts_at).valueOf()
                    );

                    const weekStart = startOfWeek(new Date(), {
                        weekStartsOn: 1,
                    });
                    const filteredAssignments = {
                        current: sortedAssignment.filter(
                            (a: Assignment) => weekStart > new Date(a.starts_at)
                        ),
                        future: sortedAssignment.filter(
                            (a: Assignment) => weekStart < new Date(a.starts_at)
                        ),
                    };

                    setAssignments(filteredAssignments);
                    setHasResolved(true);
                })
                .catch(err => {
                    setError(err.message);
                    setHasResolved(true);
                });
        },
        [firebaseConfig]
    );

    useEffect(() => {
        if (user && user.email) {
            fetch(user.email);
        }
    }, [user, fetch]);

    // Manually update the 10k feet user data in firebase
    // const refresh = () => {
    //     axios
    //         .post('/tenThousandFeet/refresh', {}, firebaseConfig)
    //         .then(res => {
    //             console.log(res);
    //         })
    //         .catch(err => {
    //             console.warn(err);
    //         });
    // };

    return (
        <TenThousandFeetContext.Provider
            value={{
                fetch,
                error,
                hasResolved,
                assignments,
            }}
        >
            {children}
        </TenThousandFeetContext.Provider>
    );
};

export default TenThousandFeetProvider;

export const useTenThousandFeet = () => useContext(TenThousandFeetContext);
