import styled, { css } from 'styled-components';

import { themedColor, getThemedColorSubtle } from 'constants/theme';

export const Text = styled.div`
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.246451px;
    margin-bottom: 3px;
    font-weight: 600;
    text-align: ${(props: { subtle?: number; align?: any }) =>
        css`
            ${props.align || 'left'}
        `};
    color: ${(props: { subtle?: number; align?: any }) =>
        props.subtle
            ? css`
                  ${getThemedColorSubtle(props.subtle)}
              `
            : css`
                  ${themedColor}
              `};
`;
