import React, { useState, useContext } from 'react';

const FORMAT_STORAGE_KEY = 'pulseTimeFormat';

export type TimeFormat = 'h:mm a' | 'H:mm';

interface TimeFormatContext {
    format: TimeFormat;
    setFormat: (t: TimeFormat) => void;
}

const timeFormatContext = React.createContext({} as TimeFormatContext);

const TimeFormatProvider: React.FC = ({ children }) => {
    const defaultFormat = 'h:mm a' as TimeFormat;
    const [format, _setFormat] = useState<TimeFormat>(defaultFormat);

    React.useEffect(() => {
        const localFormat = localStorage.getItem(
            FORMAT_STORAGE_KEY
        ) as TimeFormat;
        setFormat(localFormat || defaultFormat);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setFormat = (f: TimeFormat) => {
        if (f === format) return;
        localStorage.setItem(FORMAT_STORAGE_KEY, f);
        _setFormat(f);
    };

    return (
        <timeFormatContext.Provider value={{ format, setFormat }}>
            {children}
        </timeFormatContext.Provider>
    );
};

export default TimeFormatProvider;

export const useTimeFormat = () => useContext(timeFormatContext);
