import { css } from 'styled-components';

export const mapStyle = (style: string, value?: any) => {
    if (value) {
        if (value.constructor === Array) {
            return css`
                ${style}: ${value.map((v: number) => v + 'px ')};
            `;
        } else if (value.constructor === Number) {
            return css`
                ${style}: ${value}px;
            `;
        } else if (
            value.constructor === String &&
            value.includes('([^\\s]+(\\.(?i)(jpg|png|gif|bmp))$)')
        ) {
            return css`
                ${style}: url(${value});
            `;
        } else {
            return css`
                ${style}: ${value};
            `;
        }
    }
};
