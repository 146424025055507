import styled from 'styled-components';
import {
    themedColorAlt,
    themedElevatedBackgroundAlt,
    themedBackgroundActive,
    themedColor,
} from 'constants/theme';
import { color } from 'constants/style';
import { ReactComponent as ExpandMore } from 'assets/expand_more.svg';
import { ReactComponent as ExpandLess } from 'assets/expand_less.svg';

export const SelectValue = styled.div`
    color: ${themedColorAlt};
    font-family: inherit;
    font-size: 19px;
    line-height: 19px;
    font-weight: 600;
    ${(props: { opened: boolean }) =>
        props.opened
            ? `
      padding: 14px;
      border: 1px solid ${color.GOLD_LIGHT};
      border-radius: 3px 3px 0 0;
    `
            : `
      padding: 15px;
      border: none;
      border-radius: 3px;
    `}
    position: relative;
    &:hover {
        cursor: pointer;
    }
`;

export const SelectWrapper = styled.div`
  background: ${themedElevatedBackgroundAlt}
  &:focus {
    outline: none;
  }
  ${(props: { opened: boolean; disabled?: boolean }) =>
      props.opened ? 'border-radius: 3px 3px 0 0;' : 'border-radius: 3px;'}
  ${(props: { opened: boolean; disabled?: boolean }) =>
      props.disabled
          ? `{
          pointer-events: none;
          opacity: 0.3;
          &:hover {
            cursor: not-allowed;
          }
        }`
          : null}`;

export const OptionsWrapper = styled.ul`
    width: 100%
    height: ${(props: { opened?: boolean }) => (props.opened ? '250px' : '0')};
    opacity: ${(props: { opened?: boolean }) => (props.opened ? '1' : '0')};
    max-height: 250px;
    background: ${themedElevatedBackgroundAlt};
    position: absolute;
    left: -1px;
    top: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border: 1px solid;
    border-color: ${(props: { opened?: boolean }) =>
        props.opened ? `${color.GOLD_LIGHT}` : 'transparent'};
    border-radius: 0 0 3px 3px;
    z-index: ${(props: { opened?: boolean }) => (props.opened ? '2' : '1')};
    overflow: scroll;
    transition: height 0.4s ease;
`;

export const OptionItem = styled.li`
    padding: 15px 10px 15px;
    ${(props: { disabled?: boolean }) =>
        props.disabled
            ? `
    pointer-events: none;
    opacity: 0.3;
  `
            : null}
    &:hover, &:focus {
        outline: none;
        cursor: pointer;
        background-color: ${themedBackgroundActive};
        ${(props: { disabled?: boolean }) =>
            props.disabled
                ? `
        cursor: not-allowed;
        background-color: unset;
      `
                : null}
    }
`;
export const ShowMore = styled(ExpandMore)`
    position: absolute;
    right: 10px;
    fill: ${themedColor};
`;

export const ShowLess = styled(ExpandLess)`
    position: absolute;
    right: 10px;
    fill: ${themedColor};
`;
