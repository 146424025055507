import React from 'react';
import { useStatuses } from 'providers/Statuses';
import { StatusFilter } from './StatusFilter';
import { StatusesOverviewWrapper } from '../styled';

interface Props {
    statusesForToday: any[];
    filteredStatusTypes: string[];
    onFilteredStatusTypesChange: (statuses: string[]) => void;
}

const StatusesOverview: React.FC<Props> = props => {
    const {
        statusesForToday,
        filteredStatusTypes,
        onFilteredStatusTypesChange,
    } = props;
    const { statusTypesById } = useStatuses();

    const statusCount = statusesForToday.reduce((acc, status) => {
        const count = acc[status.status_type_id] || 0;
        acc[status.status_type_id] = count + 1;
        return acc;
    }, {});

    const handleFilterClick = (id: string, isSelected: boolean) => {
        const newFilteredStatusTypes =
            isSelected && !filteredStatusTypes.includes(id)
                ? [...filteredStatusTypes, id]
                : filteredStatusTypes.filter(status => status !== id);
        onFilteredStatusTypesChange(newFilteredStatusTypes);
    };

    return (
        <StatusesOverviewWrapper>
            {statusTypesById &&
                Object.keys(statusTypesById).map(
                    id =>
                        statusCount[id] > 0 && (
                            <StatusFilter
                                key={id}
                                statusId={id}
                                bubbleColor={statusTypesById[id].color}
                                count={statusCount[id]}
                                type={statusTypesById[id].name}
                                onClick={handleFilterClick}
                            />
                        )
                )}
        </StatusesOverviewWrapper>
    );
};

export default StatusesOverview;
