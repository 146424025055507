import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { color as colorConstants } from 'constants/style';
import { EventLink, EventWrapper, EventActionBar } from '../styled';
import standupAsset from '../assets/standup.svg';
import retroAsset from '../assets/retro.svg';
import demoAsset from '../assets/demo.svg';
import callAsset from '../assets/call.svg';
import interviewAsset from '../assets/interview.svg';
import wireframeAsset from '../assets/wireframe.svg';
import groomingAsset from '../assets/grooming.svg';
import workshopAsset from '../assets/workshop.svg';
import reviewAsset from '../assets/review.svg';
import oneOnOneAsset from '../assets/oneOnOne.svg';
import Button from 'components/Button';
import {
    IConferenceData,
    IAttendee,
    InviteResponse,
} from 'providers/Events/types';
import { useEvents } from 'providers/Events';
import { ErrorMessage } from 'components/FormField/styled';
import { useShowAllDayEvents } from 'providers/ShowAllDayEvents';

export interface EventProps {
    attendees?: IAttendee[];
    color?: string;
    conferenceData?: IConferenceData;
    endTime: string | null;
    id: string;
    link: string;
    location: string;
    name: string;
    startTime: string | null;
    status: InviteResponse | undefined;
}

const FIFTEEN_MINUTES = 15 * 60 * 1000;

function stringIncludesOneOf(str: string, words: string[]) {
    return words.some(word => str.includes(word));
}

function getImage(str: string) {
    if (str === undefined) {
        return '';
    }
    const title = str.toLowerCase();
    if (stringIncludesOneOf(title, ['standup', 'stand up', 'stand-up'])) {
        return standupAsset;
    } else if (stringIncludesOneOf(title, ['demo'])) {
        return demoAsset;
    } else if (stringIncludesOneOf(title, ['workshop', 'work shop'])) {
        return workshopAsset;
    } else if (
        stringIncludesOneOf(title, ['retro', 'retrospective', 'retrospectives'])
    ) {
        return retroAsset;
    } else if (
        stringIncludesOneOf(title, [
            'phone',
            'call',
            'chat',
            'calls',
            'screening',
        ])
    ) {
        return callAsset;
    } else if (stringIncludesOneOf(title, ['interview', 'interviews'])) {
        return interviewAsset;
    } else if (stringIncludesOneOf(title, ['wireframes', 'wire frame'])) {
        return wireframeAsset;
    } else if (stringIncludesOneOf(title, ['grooming', 'backlog'])) {
        return groomingAsset;
    } else if (
        stringIncludesOneOf(title, [
            'review',
            'code',
            'code-review',
            'peer-review',
        ])
    ) {
        return reviewAsset;
    } else if (
        stringIncludesOneOf(title, [
            '1:1',
            '1 : 1',
            '1-1',
            '1 on 1',
            '1v1',
            '1 1',
            '1 - 1',
            'one:one',
            'one : one',
            'one - one',
            'one on one',
            'one-on-one',
            ' + ',
            ' / ',
        ])
    ) {
        return oneOnOneAsset;
    } else {
        return '';
    }
}

const Event: React.FC<EventProps> = ({
    attendees,
    color = colorConstants.GOLD,
    conferenceData,
    status,
    endTime,
    id,
    link,
    location,
    name,
    startTime,
}) => {
    const { respondToInvite, eventErrors, loading } = useEvents();
    const { showAllDayEvents } = useShowAllDayEvents();
    const [conferenceUri, setConferenceUri] = useState('');
    const [allDayEvent, setAllDayEvent] = useState(false);

    // if the event has a start time, set allDayEvent to false
    useEffect(() => setAllDayEvent(!startTime), [startTime]);

    useEffect(getConferenceUri, []);

    function getConferenceUri() {
        if (!conferenceData?.entryPoints) {
            return;
        }

        const entryPoint = conferenceData.entryPoints.find(
            entry => entry.entryPointType === 'video'
        );
        if (!entryPoint) {
            return;
        }
        setConferenceUri(entryPoint.uri);
    }

    function canJoin() {
        return (
            new Date(startTime as any).getTime() - new Date().getTime() <
            FIFTEEN_MINUTES
        );
    }

    if (allDayEvent && !showAllDayEvents) {
        return null;
    } else {
        return (
            <>
                <EventWrapper status={status}>
                    <EventLink
                        onClick={() => window.open(link, '_blank')}
                        image={getImage(name)}
                        status={status}
                    >
                        <p
                            style={{
                                margin: 0,
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: 1.3,
                                marginBottom: '3px',
                            }}
                        >
                            {name}
                        </p>
                        <p
                            style={{
                                margin: 0,
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                        >
                            {startTime && dayjs(startTime).format('h:mma')} –{' '}
                            {endTime && dayjs(endTime).format('h:mma')}{' '}
                            {location && `, ${location}`}
                        </p>
                    </EventLink>
                    {conferenceData && conferenceUri && canJoin() && (
                        <Button
                            fullWidth
                            type="button"
                            appearance="primary"
                            onClick={() => window.open(conferenceUri, '_blank')}
                        >
                            Join {conferenceData.conferenceSolution.name}
                        </Button>
                    )}
                    {status === 'needsAction' &&
                        !!attendees &&
                        !!attendees.length && (
                            <EventActionBar>
                                <Button
                                    width={117}
                                    loading={loading}
                                    type="button"
                                    appearance="primary"
                                    onClick={() =>
                                        respondToInvite(
                                            id,
                                            attendees,
                                            'accepted'
                                        )
                                    }
                                >
                                    Accept
                                </Button>
                                <Button
                                    width={117}
                                    loading={loading}
                                    type="button"
                                    appearance="secondary"
                                    onClick={() =>
                                        respondToInvite(
                                            id,
                                            attendees,
                                            'tentative'
                                        )
                                    }
                                >
                                    Maybe
                                </Button>
                                <Button
                                    width={117}
                                    loading={loading}
                                    type="button"
                                    appearance="secondary"
                                    onClick={() =>
                                        respondToInvite(
                                            id,
                                            attendees,
                                            'declined'
                                        )
                                    }
                                >
                                    Decline
                                </Button>
                            </EventActionBar>
                        )}
                </EventWrapper>
                {eventErrors[id] && (
                    <ErrorMessage>{eventErrors[id]}</ErrorMessage>
                )}
            </>
        );
    }
};

export default Event;
