import styled from 'styled-components';

import { mapStyle } from '../../StyleBase';
import { Flex, FlexProps } from './Flex';

interface ContainerProps extends FlexProps {
    gutter?: number;
}

const gutter = ({ gutter = 20 }: ContainerProps) =>
    mapStyle('padding', [0, gutter]);

export const Container = styled(Flex)`
    margin: 0 auto;

    ${gutter};
`;
