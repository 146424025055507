import { css } from 'styled-components';
import { darken, lighten } from 'polished';
import theme from 'styled-theming';
import { color } from './style';

export const themedColor = theme('mode', {
    light: color.BLACK,
    dark: color.WHITE,
});

export const themedEventSpecialBackground = theme('mode', {
    light: '#faf4e9',
    dark: '#4d483c',
});

export const themedEventSpecialColor = theme('mode', {
    light: '#9b814a',
    dark: color.GOLD_DARK,
});

export const themedEventTextColor = theme('mode', {
    light: color.WHITE,
    dark: color.BLACK,
});

export const themedColorAlt = theme('mode', {
    light: color.BLACK,
    dark: `${color.WHITE}B3`,
});

export const themedColorSubtle = theme('mode', {
    light: darken(0.35, color.GRAY),
    dark: darken(0.35, color.WHITE),
});

export const themedBackground = theme('mode', {
    light: color.WHITE,
    dark: color.BLACK,
});

export const themedBackgroundHover = theme('mode', {
    light: color.GRAY_LIGHT,
    dark: color.BLACK_MED_ALT,
});

export const themedBackgroundActive = theme('mode', {
    light: darken(0.04, color.GRAY_LIGHT),
    dark: darken(0.04, color.BLACK_MED_ALT),
});

export const themedRipple = theme('mode', {
    light: color.GRAY_LIGHT,
    dark: darken(0.04, color.BLACK_MED_ALT),
});

export const themedBorder = theme('mode', {
    light: color.GRAY_LIGHT,
    dark: 'rgba(239, 239, 239, 0.05)',
});

export const themedBoxShadow = theme('mode', {
    light: '0 1px 3px rgba(0, 0, 0, 0.23)',
    dark: '0 2px 6px rgba(0,0,0,0.23)',
});

export const themedBoxShadowHover = theme('mode', {
    light: '0px 1px 8px rgba(0, 0, 0, 0.15)',
    dark: '0 2px 6px rgba(0,0,0, 0.15)',
});

export const themedBoxElevation = theme('mode', {
    light: '0 10px 20px rgba(0,0,0,0.15)',
    dark: '0 10px 20px rgba(0,0,0, 0.25)',
});

export const themedElevatedBackground = theme('mode', {
    light: color.WHITE,
    dark: color.BLACK_MED_ALT,
});

export const themedElevatedBackgroundAlt = theme('mode', {
    light: color.GRAY_LIGHT,
    dark: color.BLACK_MED_ALT,
});

export const themedElevatedBackgroundHover = theme('mode', {
    light: color.GRAY_LIGHT,
    dark: lighten(0.04, color.BLACK_MED_ALT),
});

export const themedGradient = theme('mode', {
    light: css`
    linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  `,
    dark: css`
    linear-gradient(
      to top,
      rgba(32, 33, 36, 1) 0%,
      rgba(32, 33, 36, 0) 100%
    );
  `,
});

export const themedSuccess = theme('mode', {
    light: color.GREEN,
    dark: lighten(0.04, color.GREEN),
});

export const getThemedColorSubtle = (darkenBy: number) =>
    theme('mode', {
        light: darken(darkenBy || 0.35, color.GRAY),
        dark: darken(darkenBy || 0.35, color.WHITE),
    });
