import React, { SFC } from 'react';

import Icon, { IconProps } from './Icon';
import { ButtonWrapper } from '../styled';

export interface IconButtonProps extends IconProps {
  disabled?: boolean;
  onClick: () => void;
  type?: string;
}

const IconButton: SFC<IconButtonProps> = ({
  color,
  disabled,
  name,
  onClick,
  size = 24,
  type
}) => (
  <ButtonWrapper
    name={name}
    disabled={disabled}
    onClick={onClick}
    color={color}
    type={type}
  >
    <Icon name={name} color={color} size={size} />
  </ButtonWrapper>
);

export default IconButton;
