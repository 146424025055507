const keys: any = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e: Event) {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
}

function preventDefaultForScrollKeys(e: KeyboardEvent) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

export function disableScroll() {
    window.onwheel = preventDefault;
    window.ontouchmove = preventDefault;
    document.onkeydown = preventDefaultForScrollKeys;
}

export function enableScroll() {
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
}
