import React from 'react';
import { StyledInput } from './styled';
import { ErrorMessage, FieldWrapper, Label } from '../styled';

interface Props {
    error?: string;
    id: string;
    label?: string;
    margin?: string;
    name: string;
    onBlur?: (e: React.FocusEvent<any>) => void;
    onChange: (
        eventOrPath: string | React.ChangeEvent<any>
    ) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void);
    placeholder?: string;
    touched?: boolean;
    value?: any;
    [key: string]: any;
}

const Input: React.FC<Props> = ({
    error,
    id,
    label,
    margin,
    name,
    onBlur,
    onChange,
    placeholder,
    touched,
    value,
    ...rest
}) => {
    return (
        <FieldWrapper margin={margin}>
            <Label htmlFor={id}>{label || name}</Label>
            <StyledInput
                id={id}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                {...rest}
            />
            {error && touched && <ErrorMessage>{error}</ErrorMessage>}
        </FieldWrapper>
    );
};

export default Input;
