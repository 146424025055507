import React from 'react';

import ButtonWrapper from '../styled/ButtonWrapper';
import Loading from 'components/Loading';

export interface ButtonProps {
    appearance?: string;
    children: React.ReactNode;
    disabled?: boolean;
    fullWidth?: boolean;
    loading?: boolean;
    onClick?: () => void;
    style?: React.CSSProperties;
    type: string;
    width?: number;
}

const Button: React.FC<ButtonProps> = ({
    loading,
    children,
    disabled,
    ...rest
}) => (
    <ButtonWrapper {...rest} disabled={loading || disabled}>
        {loading ? <Loading /> : children}
    </ButtonWrapper>
);

export default Button;
