// TODO: Needs lots of clean up
import React, {
    useState,
    useEffect,
    createContext,
    useContext,
    FunctionComponent,
    useCallback,
} from 'react';
import { endOfDay } from 'date-fns';

import { useAuth } from '../Auth';
import { IEventsState, IEventsContext, IAttendee, InviteResponse, IEvent } from './types';

const initialState: IEventsState = {
    hasResolved: false,
    error: '',
    eventErrors: {},
    events: [],
    loading: false,
};

const initialContext: IEventsContext = {
    ...initialState,
    respondToInvite: () => {},
    fetch: () => {},
};

const EventsContext = createContext(initialContext);

const EventsProvider: FunctionComponent = ({ children }) => {
    const { accessToken, idToken } = useAuth();
    const [error, setError] = useState(initialState.error);
    const [eventErrors, setEventErrors] = useState(initialState.eventErrors);
    const [events, setEvents] = useState(initialState.events);
    const [hasResolved, setHasResolved] = useState(initialState.hasResolved);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        const localEvents = JSON.parse(localStorage.getItem('events')!);
        if (localEvents && !hasResolved) {
            setEvents(localEvents);
            setHasResolved(true);
        }
    }, [hasResolved]);

    const fetch = useCallback(() => {
        const start = new Date();
        const end = endOfDay(new Date());
        // @ts-ignore
        window.gapi.client.calendar.events
            .list({
                calendarId: 'primary',
                deletedEvents: false,
                orderBy: 'startTime',
                singleEvents: true,
                timeMin: start.toISOString(),
                timeMax: end.toISOString(),
            })
            .then((res: any) => {
                const filteredEvents = filterDeclined(res.result.items)
                setEvents(filteredEvents);
                localStorage.setItem(
                    'events',
                    JSON.stringify(filteredEvents)
                );
                setHasResolved(true);
            })
            .catch((e: Error) => {
                setError(e.message);
            });
    }, []);

    useEffect(() => {
        if (accessToken && idToken) {
            fetch();
        }
    }, [accessToken, idToken, fetch]);

    function filterDeclined(events: IEvent[]) {
        return events.filter(event => {
            if(!event.attendees || !event.attendees.length) {
                return true;
            }
            const self = event.attendees.find(attendee => attendee.self);
            return self && self.responseStatus !== 'declined'
        })
    }

    function respondToInvite(id: string, attendees: IAttendee[], response: InviteResponse) {
      setLoading(true)
      setEventErrors({})
      const updatedAttendees = [...attendees]
      const indexOfSelf = updatedAttendees.findIndex(attendee => attendee.self)
      updatedAttendees[indexOfSelf] = {
        ...updatedAttendees[indexOfSelf],
        responseStatus: response
      }

      // @ts-ignore
      window.gapi.client.calendar.events.patch({
        calendarId: 'primary',
        eventId: id,
        attendees: updatedAttendees
      }).then((res: any) => {
        const eventIndex = events.findIndex(event => res.result.id === event.id)
        const updatedEvents = [...events]
        if(eventIndex !== -1 && response !== 'declined') {
          updatedEvents[eventIndex] = res.result
        }
          if (eventIndex !== -1 && response === 'declined') {
          updatedEvents.splice(eventIndex, 1)
        }

        setEvents(updatedEvents)
        localStorage.setItem(
          'events',
          JSON.stringify(updatedEvents)
        );
        setLoading(false)

      }).catch((err: any) => {
        setEventErrors({
          ...eventErrors,
          [id]: `Unable to accept invite: ${err.status}`
        })
        setLoading(false)
      })

    }

    return (
        <EventsContext.Provider value={{
          respondToInvite,
          eventErrors,
          events,
          error,
          fetch,
          hasResolved,
          loading
        }}>
            {children}
        </EventsContext.Provider>
    );
};

export default EventsProvider;

export const useEvents = () => useContext(EventsContext);
