import styled from 'styled-components';

import { Box } from 'components/Layout';
import {
    themedColor,
    themedElevatedBackground,
    themedElevatedBackgroundHover,
} from 'constants/theme';

export const AppsMenuWrapper = styled(Box)`
    background: ${themedElevatedBackground};
`;

export const AppLabel = styled.div`
    color: ${themedColor};
`;

export const AppIcon = styled.a`
    display: block;
    text-align: center;
    color: ${themedColor};
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 0 10px 0;
    width: 33.333333%;

    &:hover {
        background: ${themedElevatedBackgroundHover};
        color: ${themedColor};
    }
`;
