import styled from 'styled-components';
import { color } from 'constants/style';

export const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 20px 0;
    width: 90%;
`;

export const SettingsContainer = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
`;

export const Title = styled.p`
    font-size: 19px;
    font-weight: 600;
`;

export const SectionOptions = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
`;

export const Option = styled.p`
    font-size: 1em;
    white-space: pre-wrap;
    margin: 0;
`;

export const Switch = styled.input`
    position: relative;
    height: 25px;
    width: 50px;
    background: ${color.GRAY_LIGHT};
    -webkit-appearance: none;
    border-radius: 20px;
    outline: none;
    transition: 0.25s;
    &:checked {
        background: ${color.GOLD};
    }
    &::before {
        content: '';
        position: absolute;
        height: 25px;
        width: 25px;
        top: 0;
        left: 0;
        transform: scale(1.1);
        border-radius: 50%;
        background: ${color.WHITE};
        transition: 0.5s;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
    }
    &:checked:before {
        left: 25px;
    }
`;

export const SettingTile = styled.div`
    text-align: center;
    flex-grow: 1;
    background: ${color.GRAY_DARK}
    &:isActive {
        background: ${color.GOLD}
    }
`;

export const TilesContainer = styled.div`
    color: black;
    position: relative;
    display: flex;
    justify-content: space-around;

    align-items: center;
    height: 25px;
    width: 180px;
    outline: none;
`;

export const Tile = styled.div<{ isActive: boolean }>`
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    background: transparent;
    border-radius: 4px;
    margin: 1px;
    width: 100%;
    height: 100%;
    background: ${props => (props.isActive ? color.GOLD : color.GRAY_LIGHT)};
    flex-grow: 1;
`;
