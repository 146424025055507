import React from 'react';
import ReactDOM from 'react-dom';

import Providers from 'providers';
import Routes from 'routes';
import StyleBase from 'components/StyleBase';

ReactDOM.render(
  <Providers>
    <StyleBase />
    <Routes />
  </Providers>,
  document.getElementById('root')
);
